import { Injectable } from '@angular/core';
import {
  AbstractEntityTypeService,
  ArrayUtilityService,
} from '@prg/prg-core-lib';
import { Resource } from '../../../../resources/Models/resource';
import { ResourceEntitiesOperationNames } from '../model/Resource-entities-operation-names-enum';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private arrayUtilityService: ArrayUtilityService
  ) {}

  /**
   * Manage Resource Data through the entity service
   * @param  {string} entityName
   * @param {ResourceEntitiesOperationNames} operationName
   * @param {null} entity
   * @returns {Promise<any>}
   */
  public manageResourceDataByEntityServiceAsync(
    entityName: string,
    operationName: ResourceEntitiesOperationNames,
    entity?: any
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const entityType =
          await this.entityTypeService.getAllEntityTypeDataByName(entityName);
        if (entityType != null) {
          const operationRequest = entityType.operations.find(
            (operation) => operation.name == operationName
          );

          if (operationRequest != null) {
            const entityRequest: any =
              await this.entityTypeService.executeAction(
                entityName,
                operationRequest,
                entity
              );
            resolve(entityRequest);
          }
        }
      } catch (e) {
        await reject(e);
      }
    });
  }

  /**
   * Get assets and his children of the park and grouped them together
   * @param {Resource[]} parkAssets
   * @returns {Resource[]}
   */
  public getAssetsRecursivelyOnPark(parkAssets: Resource[]) {
    const parkAssetsGrouped: Resource[] = [];

    parkAssets.map((parkAsset) => {
      if (parkAsset?.resources == null || parkAsset.resources?.length == 0) {
        parkAssetsGrouped.push(parkAsset);
      } else {
        parkAssetsGrouped.push({
          ...parkAsset,
          resources: null,
        });
        const assetsChildren: Resource[] = this.arrayUtilityService.clone(
          this.getAssetsRecursivelyOnPark(parkAsset.resources)
        );
        if (assetsChildren != null && assetsChildren.length > 0) {
          assetsChildren.map((assetChildren) => {
            parkAssetsGrouped.push(assetChildren);
          });
        }
      }
    });
    return parkAssetsGrouped;
  }
}
