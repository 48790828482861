import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractAuthService } from '@prg/prg-core-lib';
import { DepartmentService } from '../../../../../Core/services/department-service/department.service';
import { Ticket, TicketTypes } from '../../../../models/ticket.model';

@Component({
  selector: 'ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent {
  @Input() ticket: Ticket;
  @Output() ticketToEdit = new EventEmitter<Ticket>();
  @Output() createWorkOrderClick = new EventEmitter<Ticket>();
  @Output() createTicketClick = new EventEmitter<Ticket>();

  constructor(
    private authService: AbstractAuthService,
    private departmentService: DepartmentService
  ) {}

  public editTicket(event: any): void {
    event.stopPropagation();
    this.ticketToEdit.emit(this.ticket);
  }

  protected readonly TicketTypes = TicketTypes;

  public onClickCreateWorkOrder(event: any): void {
    event.stopPropagation();
    this.createWorkOrderClick.emit(this.ticket);
  }

  onClickCreateTicket(event: any): void {
    event.stopPropagation();
    this.createTicketClick.emit(this.ticket);
  }
}
