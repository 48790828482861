import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ArrayUtilityService,
  BaseNote,
  EditingNoteActions,
  EditNotes,
  Filter,
  FilterGroup,
  FilterOperations,
  ObjectsUtilityService,
  PageContextService,
} from '@prg/prg-core-lib';
import { Subscription } from 'rxjs';
import {
  WorkOrder,
  WorkOrderNote,
} from '../../../../../work-orders/models/work-order-model';

import { WorkOrderEntitiesOperationNames } from '../../model/work-order-entities-operation-names-enum';

import { WorkOrderService } from '../../services/work-order.service';

@Component({
  selector: 'app-work-order-notes',
  templateUrl: './work-order-notes.page.html',
  styleUrls: ['./work-order-notes.page.scss'],
})
/**
 * Page Work Order Notes
 */
export class WorkOrderNotesPage implements OnInit, OnDestroy {
  /**
   * Worker order id
   * @type {string}
   * @private
   */
  public workOrderId: string;

  /**
   * The obs list
   * @type {WorkOrderNote[]}
   */
  public observations: WorkOrderNote[];

  /**
   * Work Order
   * @type {WorkOrder}
   */
  public workOrder: WorkOrder;

  /**
   * To clean Observables
   * @type {Subscription[]}
   */
  public subscription: Subscription;

  /**
   * Constructor
   * @param {WorkOrderService} workOrderService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {ActivatedRoute} activatedRoute
   * @param {ArrayUtilityService} arrayUtilityService
   * @param pageContextService
   */
  constructor(
    private workOrderService: WorkOrderService,
    private objectsUtilityService: ObjectsUtilityService,
    private activatedRoute: ActivatedRoute,
    private arrayUtilityService: ArrayUtilityService,
    private pageContextService: PageContextService
  ) {}

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.workOrderId = this.activatedRoute.snapshot.paramMap.get('workOrderId');
    if (this.workOrderId) {
      /*await this.getWorkOrderInfo();*/
      this.getObservations();
      this.subscription = this.pageContextService.subscribeVariable(
        'workOrder',
        this.workOrderId,
        this.workOrderUpdate
      );
    } else {
      //Todo Redirect to Home?
    }
  }

  public workOrderUpdate = (data) => {
    this.workOrder = this.objectsUtilityService.clone(data);
  };

  /**
   * Method that gets observations for this work order
   * @returns {Promise<void>}
   */
  private async getObservations(): Promise<void> {
    try {
      const notesResponse =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workordernote',
          WorkOrderEntitiesOperationNames.Filtered,
          new FilterGroup({
            filterCollections: [
              new Filter({
                startGroup: true,
                propertyName: 'WorkOrderId',
                filterOperation: FilterOperations.EqualTo,
                value: this.workOrderId,
              }),
            ],
          })
        );
      if (notesResponse?.entity != null) {
        this.observations = this.arrayUtilityService.clone(
          notesResponse?.entity
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Method that saves a new obs
   * @param {BaseNote} event
   * @returns {Promise<void>}
   */
  public async handleNewObs(event: BaseNote): Promise<void> {
    try {
      const newNoteResponse =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workordernote',
          WorkOrderEntitiesOperationNames.Create,
          event
        );

      if (newNoteResponse?.entity != null) {
        this.observations.push(newNoteResponse.entity);
        event.id = newNoteResponse.entity.id;
      }
      /* this.observations = [...this.observations];*/
    } catch (e) {
      this.observations = this.arrayUtilityService.clone(this.observations);
    }
  }

  /**
   * Method that saves an edited obs
   * @param {EditNotes} event
   * @returns {Promise<void>}
   */
  public async handleEditObs(event: EditNotes): Promise<void> {
    try {
      const index = this.observations.findIndex(
        (obs) => obs.id === event.note.id
      );

      if (event.typeEditing == EditingNoteActions.Editing) {
        const editObsResponse =
          await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
            'workordernote',
            WorkOrderEntitiesOperationNames.Update,
            event.note
          );

        if (editObsResponse != null) {
          this.observations[index] = this.objectsUtilityService.clone(
            event.note
          );
        } else {
          this.observations = this.arrayUtilityService.clone(this.observations);
        }
      } else {
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workordernote',
          WorkOrderEntitiesOperationNames.Delete,
          event.note
        );

        this.observations.splice(index, 1);
      }
    } catch (e) {
      this.observations = this.arrayUtilityService.clone(this.observations);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pageContextService.unsubscribe(this.subscription);
  }
}
