import { BaseEntity } from '@prg/prg-core-lib';

/**
 * Enum that defines the status of resources /LookupTable
 */
export enum ResourceStates {
  Active = 'resourcestates.active',
  Spare = 'resourcestates.spare',
  Scrap = 'resourcestates.scrap',
}

/**
 * Enum that defines the type of resources /LookupTable
 */
export enum ResourceTypes {
  Park = 'resourcetypes.park',
  Inverter = 'resourcetypes.inverter',
  String = 'resourcetypes.string',
  Panel = 'resourcetypes.panel',
  PowerTransformer = 'resourcetypes.powertransformer',
  JunctionBox = 'resourcetypes.junctionbox',
  Charger = 'resourcetypes.charger',
  ChargingPort = 'resourcetypes.chargingport',
}
/**
 * Model that defines the assets related to work orders
 */
export class Resource extends BaseEntity {
  /**
   * Description of the asset
   * @type {string}
   */
  public description?: string;
  /**
   * Type of resource
   * @type {string}
   */
  public resourceTypeId?: string | ResourceTypes; // Lookup Table
  /**
   * Parent Resource id
   * @type {string | null}
   */
  public resourceId?: string | null; // parent
  /**
   * List of children resources
   * @type {Resource[]}
   */
  public resources?: Resource[];
  /**
   * Image of the resource
   * @type {string}
   */
  public imageUrl?: string;
  /**
   *  Address of the resource (e.g. park address)
   * @type {string}
   */
  public address?: string;

  /**
   *  Defines the status of resource
   * @type {boolean}
   */
  public resourceStateId?: string | ResourceStates = ResourceStates.Active;
  /**
   * position of asset
   * @type {string}
   */
  public order?: number;

  /**
   * client id
   */
  public clientId?: string;

  /**
   * Serial Number of resource
   * @type {string}
   */
  public serialNumber?: string;

  /**
   * client
   */
  public Client?: any;

  /**
   * Id of root resource
   * @type {string}
   */
  public rootResourceId?: string;

  /**
   * order Path
   * @type {string}
   */
  public orderPath?: string;

  /**
   * Display name
   * @type {string}
   */
  public displayName?: string;

  /**
   * latitude
   * @type {number}
   */
  public latitude?: number;
  /**
   * longitude
   * @type {number}
   */
  public longitude?: number;

  /**
   * Start Date
   * @type {Date | string}
   */
  public startDate?: Date | string;

  /**
   * warranty End Date
   * @type {Date | string}
   */
  public warrantyEndDate?: Date | string;

  /**
   * user solar schedule
   */
  public useSolarSchedule?: boolean = false;

  /**
   * Constructor
   * @param {Partial<Resource>} init
   */
  constructor(init?: Partial<Resource>) {
    super();
    Object.assign(this, init);
  }
}
