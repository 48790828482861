import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  /* {
    path: '',
    component: PrgLayoutsHeaderSidebarComponent,
    children: [{ path: '', component: DashboardAuxComponent }],
  },
  {
    path: '',
    component: PrgLayoutsHeaderSidebarComponent,
    children: [{ path: ':id', component: DashboardAuxComponent }],
  },*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TicketRoutingModule {}
