import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObjectsUtilityService } from '@prg/prg-core-lib';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  TimerUnits,
  WorkOrder,
} from '../../../../../work-orders/models/work-order-model';
import { WorkOrderService } from '../../services/work-order.service';

@Component({
  selector: 'app-work-order-timers',
  templateUrl: './work-order-timers.component.html',
  styleUrls: ['./work-order-timers.component.scss'],
})
export class WorkOrderTimersComponent implements OnInit {
  public timers: WorkOrderTotalTime = {
    travelTime: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    },
    workTime: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    },
  };

  /**
   * Work Order
   * @type {WorkOrder}
   */
  public workOrder: WorkOrder;
  public pageTranslationPath: string =
    'pages-techparts-solar-mobile.work-order-panel.dialog-timers.';

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private objectsUtilityService: ObjectsUtilityService,
    private workOrderService: WorkOrderService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.workOrder = this.objectsUtilityService.clone(
      this.dynamicDialogConfig?.data['workOrder']
    );
    if (this.workOrder['travelTimer'].totalTimer != null) {
      Object.assign(
        this.timers.travelTime,
        this.workOrder['travelTimer'].totalTimer
      );
    }
    if (this.workOrder['workTimer']?.totalTimer != null) {
      Object.assign(
        this.timers.workTime,
        this.workOrder['workTimer'].totalTimer
      );
    }
  }

  onCancelAction(): void {
    this.dynamicDialogRef.close();
  }

  async onSaveTimers(): Promise<void> {
    const timersToUpdate: WorkOrderTotalTime = {
      workTime: {
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
      travelTime: {
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
    };

    if (
      Object.keys(this.timers?.workTime).some(
        (key) => this.timers.workTime[key] != null
      )
    ) {
      const checkNoneChanges = Object.keys(this.timers?.workTime).every(
        (key) => {
          if (
            this.workOrder['workTimer']?.totalTimer != null &&
            Object.prototype.hasOwnProperty.call(
              this.workOrder['workTimer']?.totalTimer,
              key
            ) &&
            this.timers?.workTime[key] ==
              this.workOrder['workTimer']?.totalTimer[key]
          ) {
            return true;
          } else {
            return null;
          }
        }
      );
      if (checkNoneChanges) {
        timersToUpdate.workTime = null;
      } else {
        timersToUpdate.workTime = this.objectsUtilityService.clone(
          this.timers?.workTime
        );
      }
    } else {
      timersToUpdate.workTime = null;
    }

    if (
      Object.keys(this.timers?.travelTime).some(
        (key) => this.timers.travelTime[key] != null
      )
    ) {
      const checkNoneChanges = Object.keys(this.timers?.travelTime).every(
        (key) => {
          if (
            this.workOrder['travelTimer']?.totalTimer != null &&
            Object.prototype.hasOwnProperty.call(
              this.workOrder['travelTimer']?.totalTimer,
              key
            ) &&
            this.timers?.travelTime[key] ==
              this.workOrder['travelTimer']?.totalTimer[key]
          ) {
            return true;
          } else {
            return null;
          }
        }
      );
      if (checkNoneChanges) {
        timersToUpdate.travelTime = null;
      } else {
        timersToUpdate.travelTime = this.objectsUtilityService.clone(
          this.timers?.travelTime
        );
      }
    } else {
      timersToUpdate.travelTime = null;
    }
    if (timersToUpdate.travelTime != null || timersToUpdate.workTime != null) {
      if (await this.confirmUpdateDialog()) {
        try {
          const response =
            await this.workOrderService.updateWorkOrderTimersAsync(
              this.workOrder.id,
              timersToUpdate
            );
          if (response != null) {
            this.dynamicDialogRef.close(response);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        return;
      }
    } else {
      this.dynamicDialogRef.close();
    }
  }

  /**
   * Show a confirmation dialog to confirm update
   * @returns {Promise<boolean>}
   */
  public confirmUpdateDialog() {
    return new Promise<boolean>((resolve, reject) => {
      try {
        this.confirmationService.confirm({
          key: 'confirmationUpdateTimers',
          message: this.translateService.instant(
            this.pageTranslationPath + 'warning-change-timers.message'
          ),
          header: this.translateService.instant(
            this.pageTranslationPath + 'warning-change-timers.header'
          ),
          icon: this.translateService.instant(
            this.pageTranslationPath + 'warning-change-timers.icon'
          ),
          rejectLabel: this.translateService.instant(
            this.pageTranslationPath + 'warning-change-timers.reject-label'
          ),
          acceptLabel: this.translateService.instant(
            this.pageTranslationPath + 'warning-change-timers.accept-label'
          ),
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}

export interface WorkOrderTotalTime {
  workTime: TimerUnits;
  travelTime: TimerUnits;
}
