/**
 * Constant that defines the properties for each column of the Consumable Table
 */
import { BaseColumn } from '@prg/prg-core-lib';

export const ConsumableColumns: BaseColumn[] = [
  new BaseColumn({
    key: 'name',
    readonly: true,
    basePathTranslation: 'pages-techparts-solar-mobile.consumable-form.fields.',
  }),
  new BaseColumn({
    key: 'quantity',
    readonly: true,
    basePathTranslation: 'pages-techparts-solar-mobile.consumable-form.fields.',
  }),
];
