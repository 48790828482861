import { BaseEntity } from '@prg/prg-core-lib';

/**
 * Class that defines base consumables
 */
export abstract class BaseConsumable extends BaseEntity {
  /**
   * Description of the consumable
   * @type {string}
   */
  public description: string;

  /**
   * Consumable units
   * @type {string}
   */
  public unit: string;

  /**
   * Workspace id
   */
  public workspaceId: string;
  /**
   * Base Data type id
   * @type {string}
   */
  public dataTypeId: string;

  /**
   * Constructor
   * @param {Partial<BaseConsumable>} init
   */
  protected constructor(init?: Partial<BaseConsumable>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Class that defines consumables used on work orders
 */
export class Consumable extends BaseConsumable {
  /**
   * Constructor
   * @param {Partial<Consumable>} init
   */
  constructor(init?: Partial<Consumable>) {
    super();
    Object.assign(this, init);
  }
}

/**
 * Class that defines consumables used on work orders
 */
export class WorkOrderConsumable extends BaseConsumable {
  /**
   *  Work Order id
   */
  public workOrderId: string | null;

  /**
   * quantity of consumable
   * @type {string}
   */
  public quantity: string;

  /**
   * id of the base consumable
   * @type {string}
   */
  public consumableId: string;

  /**
   * the source of the conumable used
   * @type {string}
   */
  public usedConsumableSourceId?: string | UsedConsumableSources;

  /**
   * Constructor
   * @param {Partial<WorkOrderConsumable>} init
   */
  constructor(init?: Partial<WorkOrderConsumable>) {
    super();
    Object.assign(this, init);
  }
}
/**
 * Enum that defines the source of consumables used on work order
 */
export enum UsedConsumableSources {
  Park = 'usedConsumableSources.park',
  General = 'usedConsumableSources.general',
}
