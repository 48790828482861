import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractEntityTypeService,
  AbstractLookupTableService,
  AbstractStateModelsService,
  ApiGenericService,
  ArrayUtilityService,
  AuthGuard,
  DynamicDialogService,
  EntityType,
  ExecutedAction,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  GenericRelation,
  InterceptorConfig,
  LookupTableItem,
  ObjectsUtilityService,
  OrderTypes,
  PrgFilterMenuItem,
  PrgFilterMenuItemChild,
  StateModel,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import * as dayjs from 'dayjs';

import * as weekday from 'dayjs/plugin/weekday';

import { TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';
import { Department } from '../../Core/models/department.model';
import { DepartmentService } from '../../Core/services/department-service/department.service';
import { TicketFormComponent } from '../../tickets/components/ticket-list/components/ticket-form/ticket-form.component';
import {
  Ticket,
  TicketGroup,
  TicketsGroupByTypes,
  TicketTypes,
} from '../../tickets/models/ticket.model';
import { TicketService } from '../../tickets/services/ticket.service';
import { WorkOrder } from '../../work-orders/models/work-order-model';
import {
  KanbanBoardFilters,
  KanbanBoardTicketOrder,
  TicketGroupOrderBy,
} from './kanban-board-filters-and-order';
import {
  EditCardClickObject,
  UpdateTicketProperty,
} from './kanban-card/kanban-card.component';

dayjs.extend(weekday);

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss'],
})
export class KanbanBoardComponent implements OnInit {
  /**
   * Current dragged card
   * @type {any}
   */
  private draggedComponent: any;

  /**
   * A list of tickets
   * @type {Ticket[]}
   */
  private ticketsToDo: Ticket[] = [];
  /**
   * A list of tickets grouped by property
   * @type {TreeNode[]}
   */
  public ticketsToDoGrouped: TreeNode[] = [];

  /**
   * to track position of scroll before reload template
   * @type {number[]}
   * @private
   */
  private scrollPositions: Map<string, number> = new Map<string, number>();

  /**
   * base page translation path
   * @type {string}
   * @private
   */
  public basePageTranslationPath: string = 'pages.kanban-board.';
  /**
   * Ticket options for group by
   * @type {({id: TicketsGroupByTypes, label: any} | {id: TicketsGroupByTypes, label: any} | {id: TicketsGroupByTypes, label: any})[]}
   */
  public optionsGroupByType = [
    {
      id: TicketsGroupByTypes.Group,
      label: this.translateService.instant(
        this.basePageTranslationPath + 'options-group-by.group.label'
      ),
    },
    {
      id: TicketsGroupByTypes.Park,
      label: this.translateService.instant(
        this.basePageTranslationPath + 'options-group-by.park.label'
      ),
    },
    {
      id: TicketsGroupByTypes.Client,
      label: this.translateService.instant(
        this.basePageTranslationPath + 'options-group-by.client.label'
      ),
    },
  ];

  /**
   * Aux to use Enum TicketsGroupByTypes on Template
   * @type {TicketsGroupByTypes}
   */
  public auxTicketGroupBy = TicketsGroupByTypes;
  /**
   * Aux to use Enum OrderTypes on Template
   * @type {TicketsGroupByTypes}
   */
  public orderTypeAux = OrderTypes;
  /**
   * Selected ticket group by
   * @type {TicketsGroupByTypes | string}
   */
  public groupByType: TicketsGroupByTypes | string = TicketsGroupByTypes.Group;
  /**
   * Property of type DynamicDialogRef to control the dialog of ticket form
   * @type {DynamicDialogRef}
   * @private
   */
  private ticketFormRef: DynamicDialogRef;
  /**
   * A list of group columns visible on view
   * @type {TicketGroup[]}
   */
  public ticketsGroupsColumnsVisible: string[] = [];
  /**
   * A list of all tickets groups
   * @type {TicketGroup[]}
   */
  public optionsTicketsGroups: TicketGroup[];
  /**
   * Last overlay id open on card
   * @type {string}
   */
  public lastOverlayTicketIdOpen: string;
  /**
   * A list of filter menu items to build filter menu
   * @type {PrgFilterMenuItem[]}
   */
  public filterMenu: PrgFilterMenuItem[] =
    this.arrayUtilityService.clone(KanbanBoardFilters);
  /**
   * Ticket order
   * @type {TicketGroupOrderBy[]}
   */
  public ticketOrder: TicketGroupOrderBy[] = this.arrayUtilityService.clone(
    KanbanBoardTicketOrder
  );
  /**
   * A flag to know when all the required configs are loaded on init
   * @type {boolean}
   */
  public isLoadingOptions: boolean = true;
  /**
   * A list of all tickets types
   * @type {LookupTableItem[]}
   */
  public ticketTypesItems: LookupTableItem[] = [];
  /**
   * Available options for ticket states
   * @type {any[]}
   */
  public optionsForTicketState: any[] = [];
  /**
   * Selected ticket state filter
   * @type {any}
   */
  public selectedFilterTicketState: any = 'allOpen';
  /**
   * A flag to show/hide multiselect ou dropdown for ticket types filter
   * @type {boolean}
   */
  public showGlobalFilterTicketState: boolean = true;

  /**
   * A map to cache state models
   * @type {Map<string, StateModel>}
   * @private
   */
  private mapStateModels: Map<string, StateModel> = new Map<
    string,
    StateModel
  >();

  /**
   * Current ticket State Filters
   * @type {Filter[]}
   */
  public ticketStateFilters: Filter[] = [];
  /**
   * Current List of filters
   * @type {Filter[]}
   */
  public ticketFilters: Filter[] = [
    new Filter({
      propertyName: 'TicketTypeId',
      filterOperation: FilterOperations.EqualTo,
      value: TicketTypes.Todo,
      startGroup: true,
      filterExpression: FilterExpressions.Or,
    }),
    new Filter({
      propertyName: 'TicketTypeId',
      filterOperation: FilterOperations.EqualTo,
      value: TicketTypes.Occurrence,
    }),
  ];

  /**
   * Show/Hide work order modal
   * @type {boolean}
   */
  public displayWorkOrderModal: boolean = false;

  /**
   * new Work Order
   * @type {WorkOrder}
   */
  public newWorkOrder: WorkOrder = null;

  /**
   * work order form view mode
   * @type {ViewMode}
   */
  public workOrderViewMode: ViewMode = ViewMode.Edit;

  /**
   * Work order entity type
   * @type {EntityType}
   */
  public entityTypeWorkOrder: EntityType = null;
  /**
   * Departments of department manager
   * @type {Department[]}
   * @private
   */
  private departmentManagerDepartments: Department[];
  /**
   * user can create ticket
   * @type {boolean}
   * @private
   */
  public userCanCreateTicket: boolean = true;

  public onInit: boolean = true;

  private storageFilters: {
    ticketFilters: Filter[];
    filterMenu: PrgFilterMenuItem[];
    selectedFilterTicketState: any;
    showGlobalFilterTicketState: boolean;
    ticketOrder: TicketGroupOrderBy[];
  } = {
    ticketFilters: null,
    filterMenu: null,
    selectedFilterTicketState: null,
    showGlobalFilterTicketState: null,
    ticketOrder: null,
  };

  /**
   * Contructor
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {TicketService} ticketService
   * @param {DynamicDialogService} dialogService
   * @param {TranslateService} translateService
   * @param {ApiGenericService} apiService
   * @param {UtilityService} utilityService
   * @param {AbstractLookupTableService} lookupTableService
   * @param {AbstractStateModelsService} stateModelsService
   * @param entityTypeService
   */
  constructor(
    private arrayUtilityService: ArrayUtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private ticketService: TicketService,
    private dialogService: DynamicDialogService,
    private translateService: TranslateService,
    private apiService: ApiGenericService,
    private utilityService: UtilityService,
    private lookupTableService: AbstractLookupTableService,
    private stateModelsService: AbstractStateModelsService,
    private entityTypeService: AbstractEntityTypeService,
    private departmentService: DepartmentService,
    private authGuard: AuthGuard
  ) {}

  async ngOnInit() {
    this.setUserPermissions();
    this.getUserLastFiltersOnStorage();

    await this.getStaticOptionsForFilters();

    if (this.showGlobalFilterTicketState == true) {
      await this.onChangeGlobalFilterTicketState(
        { value: this.selectedFilterTicketState },
        false
      );
    } else {
      await this.onChangeFilterTicketState(
        { value: this.selectedFilterTicketState },
        false
      );
    }

    /* await this.getToDoTickets(this.ticketFilters);
    await this.getTicketsGroups();
    await this.getFilterOptionsForTicketStates();*/
    this.isLoadingOptions = false;
  }

  private getUserLastFiltersOnStorage() {
    try {
      this.getAndSetTicketPreferencesFromStorageObject();
      if (this.storageFilters.ticketFilters != null) {
        this.ticketFilters = this.arrayUtilityService.clone(
          this.storageFilters.ticketFilters
        );
        this.selectedFilterTicketState =
          this.storageFilters.selectedFilterTicketState;

        this.showGlobalFilterTicketState =
          this.storageFilters.showGlobalFilterTicketState;
        if (
          this.storageFilters.ticketOrder != null &&
          this.storageFilters.ticketOrder.length
        ) {
          this.ticketOrder = this.storageFilters.ticketOrder;
        }
      }
    } catch (e) {
      localStorage.removeItem(STORAGE_TICKET_PREFERENCES_KEY);
      window.location.reload();
    }
  }

  private getAndSetTicketPreferencesFromStorageObject() {
    const storageFiltersInString = localStorage.getItem(
      STORAGE_TICKET_PREFERENCES_KEY
    );
    if (storageFiltersInString != null) {
      this.storageFilters = JSON.parse(storageFiltersInString);
    }
  }

  private setTicketPreferencesOnStorage() {
    try {
      this.storageFilters.ticketFilters = this.arrayUtilityService.clone(
        this.ticketFilters
      );
      this.storageFilters.filterMenu = this.arrayUtilityService.clone(
        this.filterMenu
      );

      this.storageFilters.selectedFilterTicketState =
        this.selectedFilterTicketState;

      this.storageFilters.showGlobalFilterTicketState =
        this.showGlobalFilterTicketState;

      this.storageFilters.ticketOrder = this.arrayUtilityService.clone(
        this.ticketOrder
      );

      localStorage.setItem(
        STORAGE_TICKET_PREFERENCES_KEY,
        JSON.stringify(this.storageFilters)
      );
    } catch (e) {
      localStorage.removeItem(STORAGE_TICKET_PREFERENCES_KEY);
      this.storageFilters = {
        ticketFilters: null,
        ticketOrder: null,
        showGlobalFilterTicketState: null,
        selectedFilterTicketState: null,
        filterMenu: null,
      };
    }
  }

  verifyAllPropertyNames(): boolean {
    // Check if all propertyNames are present

    const propertyNamesArray = this.arrayUtilityService.clone(
      KanbanBoardFilters.map((x) => x.propertyName)
    );
    const extractedPropertyNames = this.filterMenu.map(
      (obj) => obj.propertyName
    );
    // Both conditions must be true
    return propertyNamesArray.every((propertyName) =>
      extractedPropertyNames.includes(propertyName)
    );
  }

  public async getStaticOptionsForFilters() {
    this.ticketTypesItems =
      await this.lookupTableService.getLookupTableItemsByLookupTableIdAsync(
        'tickettypes'
      );
    if (
      this.storageFilters.filterMenu != null &&
      this.verifyAllPropertyNames()
    ) {
      this.filterMenu = this.arrayUtilityService.clone(
        this.storageFilters.filterMenu
      );
      this.updateDatesOnFilterMenu();
    } else {
      if (this.ticketTypesItems != null && this.ticketTypesItems.length > 0) {
        const filterTicketTypeId = this.filterMenu.find(
          (x) => x.propertyName == 'TicketTypeId'
        );
        filterTicketTypeId.items = [];
        this.ticketTypesItems.forEach((x) => {
          filterTicketTypeId.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
              active:
                x.id == TicketTypes.Todo || x.id == TicketTypes.Occurrence,
            })
          );
        });
      }
      const responsePriorities =
        await this.lookupTableService.getLookupTableItemsByLookupTableIdAsync(
          'ticketpriorities'
        );
      if (responsePriorities != null && responsePriorities.length > 0) {
        const filterTicketPriorityId = this.filterMenu.find(
          (x) => x.propertyName == 'TicketPriorityId'
        );
        filterTicketPriorityId.items = [];
        responsePriorities.forEach((x) => {
          filterTicketPriorityId.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        });
      }
      const filterDueDate = this.filterMenu.find(
        (x) => x.propertyName == 'DueDate'
      );

      const weekDay = dayjs().weekday();
      const fistDayOfTheWeek = this.utilityService
        .getIsoStringInLocalTime(
          new Date().setDate(new Date().getDate() - weekDay)
        )
        .split('T')[0];
      const lastDayOfTheWeek = this.utilityService
        .getIsoStringInLocalTime(
          new Date().setDate(new Date().getDate() + (6 - weekDay))
        )
        .split('T')[0];
      const lastDayOfNextWeek = this.utilityService
        .getIsoStringInLocalTime(
          new Date().setDate(new Date().getDate() + (6 - weekDay + 7))
        )
        .split('T')[0];
      const fistDayAfterTwoWeeks = this.utilityService
        .getIsoStringInLocalTime(
          new Date().setDate(new Date().getDate() + (6 - weekDay + 7 + 1))
        )
        .split('T')[0];

      filterDueDate.items = [
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.LessThan,
          value: this.utilityService
            .getIsoStringInLocalTime(new Date())
            .split('T')[0],
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.overdue.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.EqualTo,
          value: this.utilityService
            .getIsoStringInLocalTime(new Date())
            .split('T')[0],
          label:
            this.basePageTranslationPath + 'filter-options.duedate.today.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.EqualTo,
          value: this.utilityService
            .getIsoStringInLocalTime(
              new Date().setDate(new Date().getDate() + 1)
            )
            .split('T')[0],
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.tomorrow.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: fistDayOfTheWeek,
          value2: lastDayOfTheWeek,
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.thisweek.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: lastDayOfTheWeek,
          value2: lastDayOfNextWeek,
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.nextweek.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.GreaterThanOrEqualTo,
          value: fistDayAfterTwoWeeks,
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.future.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.IsNull,
          value: null,
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.nodate.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: null,
          value2: null,
          label:
            this.basePageTranslationPath +
            'filter-options.duedate.daterange.label',
          translatedItem: true,
          active: false,
          customDateField: true,
        }),
      ];

      const filterOpenOn = this.filterMenu.find(
        (x) => x.propertyName == 'OpenOn'
      );

      const today = dayjs();
      const todayStartOfDayFormatted =
        this.utilityService.getIsoStringInLocalTime(
          today
            .set('hours', 0)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('milliseconds', 0)
            .toDate()
        );
      const todayNowFormatted = this.utilityService.getIsoStringInLocalTime(
        today.toDate()
      );

      const fistDayOfTheWeekWithTime =
        this.utilityService.getIsoStringInLocalTime(
          today.startOf('week').toDate()
        );

      const fistDayOfTheLast15DaysWithTime =
        this.utilityService.getIsoStringInLocalTime(
          today
            .add(-14, 'day')
            .set('hours', 0)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('milliseconds', 0)
            .toDate()
        );

      const firstDayOfMonthWithTime =
        this.utilityService.getIsoStringInLocalTime(
          today.startOf('month').toDate()
        );

      const firstDayOfYearWithTime =
        this.utilityService.getIsoStringInLocalTime(
          today.startOf('year').toDate()
        );

      filterOpenOn.items = [
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: todayStartOfDayFormatted,
          value2: todayNowFormatted,
          label:
            this.basePageTranslationPath + 'filter-options.openon.today.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: fistDayOfTheWeekWithTime,
          value2: todayNowFormatted,
          label:
            this.basePageTranslationPath +
            'filter-options.openon.thisweek.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: fistDayOfTheLast15DaysWithTime,
          value2: todayNowFormatted,
          label:
            this.basePageTranslationPath +
            'filter-options.openon.last15days.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: firstDayOfMonthWithTime,
          value2: todayNowFormatted,
          label:
            this.basePageTranslationPath +
            'filter-options.openon.thismonth.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: firstDayOfYearWithTime,
          value2: todayNowFormatted,
          label:
            this.basePageTranslationPath +
            'filter-options.openon.thisyear.label',
          translatedItem: true,
          active: false,
        }),
        new PrgFilterMenuItemChild({
          filterOperation: FilterOperations.Between,
          value: null,
          value2: null,
          label:
            this.basePageTranslationPath +
            'filter-options.openon.daterange.label',
          translatedItem: true,
          active: false,
          customDateField: true,
        }),
      ];
    }
  }

  private updateDatesOnFilterMenu() {
    try {
      const filterDueDate = this.filterMenu.find(
        (x) => x.propertyName == 'DueDate'
      );

      if (filterDueDate != null) {
        const weekDay = dayjs().weekday();
        const fistDayOfTheWeek = this.utilityService
          .getIsoStringInLocalTime(
            new Date().setDate(new Date().getDate() - weekDay)
          )
          .split('T')[0];
        const lastDayOfTheWeek = this.utilityService
          .getIsoStringInLocalTime(
            new Date().setDate(new Date().getDate() + (6 - weekDay))
          )
          .split('T')[0];
        const lastDayOfNextWeek = this.utilityService
          .getIsoStringInLocalTime(
            new Date().setDate(new Date().getDate() + (6 - weekDay + 7))
          )
          .split('T')[0];
        const fistDayAfterTwoWeeks = this.utilityService
          .getIsoStringInLocalTime(
            new Date().setDate(new Date().getDate() + (6 - weekDay + 7 + 1))
          )
          .split('T')[0];

        filterDueDate.items[0].value = this.utilityService
          .getIsoStringInLocalTime(new Date())
          .split('T')[0];

        filterDueDate.items[1].value = this.utilityService
          .getIsoStringInLocalTime(new Date())
          .split('T')[0];

        filterDueDate.items[2].value = this.utilityService
          .getIsoStringInLocalTime(new Date().setDate(new Date().getDate() + 1))
          .split('T')[0];

        filterDueDate.items[3].value = fistDayOfTheWeek;

        filterDueDate.items[3].value2 = lastDayOfTheWeek;

        filterDueDate.items[4].value = lastDayOfTheWeek;

        filterDueDate.items[4].value2 = lastDayOfNextWeek;

        filterDueDate.items[5].value = fistDayAfterTwoWeeks;

        if (
          filterDueDate.items[7].inputCustomDateField != null &&
          filterDueDate.items[7].inputCustomDateField.length
        ) {
          filterDueDate.items[7].inputCustomDateField[0] = new Date(
            filterDueDate.items[7].inputCustomDateField[0]
          );
          filterDueDate.items[7].inputCustomDateField[1] = new Date(
            filterDueDate.items[7].inputCustomDateField[1]
          );
        }
      }

      const filterOpenOn = this.filterMenu.find(
        (x) => x.propertyName == 'OpenOn'
      );
      if (filterOpenOn != null) {
        const today = dayjs();
        const todayStartOfDayFormatted =
          this.utilityService.getIsoStringInLocalTime(
            today
              .set('hours', 0)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0)
              .toDate()
          );
        const todayNowFormatted = this.utilityService.getIsoStringInLocalTime(
          today.toDate()
        );

        const fistDayOfTheWeekWithTime =
          this.utilityService.getIsoStringInLocalTime(
            today.startOf('week').toDate()
          );

        const fistDayOfTheLast15DaysWithTime =
          this.utilityService.getIsoStringInLocalTime(
            today
              .add(-14, 'day')
              .set('hours', 0)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0)
              .toDate()
          );

        const firstDayOfMonthWithTime =
          this.utilityService.getIsoStringInLocalTime(
            today.startOf('month').toDate()
          );

        const firstDayOfYearWithTime =
          this.utilityService.getIsoStringInLocalTime(
            today.startOf('year').toDate()
          );

        filterOpenOn.items[0].value = todayStartOfDayFormatted;
        filterOpenOn.items[0].value2 = todayNowFormatted;

        filterOpenOn.items[1].value = fistDayOfTheWeekWithTime;
        filterOpenOn.items[1].value2 = todayNowFormatted;

        filterOpenOn.items[2].value = fistDayOfTheLast15DaysWithTime;
        filterOpenOn.items[2].value2 = todayNowFormatted;

        filterOpenOn.items[3].value = firstDayOfMonthWithTime;
        filterOpenOn.items[3].value2 = todayNowFormatted;

        filterOpenOn.items[4].value = firstDayOfYearWithTime;
        filterOpenOn.items[4].value2 = todayNowFormatted;

        if (
          filterOpenOn.items[5].inputCustomDateField != null &&
          filterOpenOn.items[5].inputCustomDateField.length
        ) {
          filterOpenOn.items[5].inputCustomDateField[0] = new Date(
            filterOpenOn.items[5].inputCustomDateField[0]
          );
          filterOpenOn.items[5].inputCustomDateField[1] = new Date(
            filterOpenOn.items[5].inputCustomDateField[1]
          );
        }
      }
    } catch (e) {
      localStorage.removeItem(STORAGE_TICKET_PREFERENCES_KEY);
      window.location.reload();
    }
  }

  public async getToDoTickets(
    filters: Filter[],
    addGroupsWithoutToDos: boolean = false
  ) {
    try {
      const response = await this.ticketService.getTickets(
        new FilterGroup({
          filterCollections: filters,
          pageIndex: null,
          pageSize: null,
        })
      );

      this.ticketsToDo = response?.entity;

      this.groupByAndSort(addGroupsWithoutToDos);
      this.getOptionsForFiltersPresentOnTickets();
    } catch (e) {
      console.log(e);
    }
  }

  public groupByAndSort(addGroupsWithoutToDos: boolean = false) {
    this.ticketsToDoGrouped = this.arrayUtilityService.clone(
      this.arrayUtilityService.groupByField(this.ticketsToDo, this.groupByType)
    );
    let indexGroupEmpty = this.ticketsToDoGrouped.findIndex(
      (x) => x.data.name == undefined || false
    );
    if (indexGroupEmpty == -1) {
      this.ticketsToDoGrouped.push({
        data: {
          name: undefined,
        },
        children: [],
        expanded: true,
      });
    }

    this.ticketsToDoGrouped.forEach((x) => {
      if (x.data.name != undefined || x.data.name != null) {
        if (this.arrayUtilityService.isNotNullOrEmptyArray(x.children)) {
          if (
            x.children[0]?.data[this.groupByType.replace('Id', 'Name')] != null
          ) {
            x.data['displayName'] =
              x.children[0].data[this.groupByType.replace('Id', 'Name')];
          }
        }
      } else {
        x.data['displayName'] =
          this.groupByType == TicketsGroupByTypes.Group
            ? this.translateService.instant(
                this.basePageTranslationPath + 'options-group-by.group.empty'
              )
            : this.groupByType == TicketsGroupByTypes.Client
            ? this.translateService.instant(
                this.basePageTranslationPath + 'options-group-by.client.empty'
              )
            : this.translateService.instant(
                this.basePageTranslationPath + 'options-group-by.park.empty'
              );
      }
      x.data['visible'] = true;
      x.data['orderGroup'] = this.arrayUtilityService.clone(this.ticketOrder);
    });

    this.ticketsToDoGrouped.sort((a, b) => {
      return a.data['displayName'].localeCompare(b.data['displayName']);
    });

    indexGroupEmpty = this.ticketsToDoGrouped.findIndex(
      (x) => x.data.name == undefined || false
    );
    const groupEmpty = this.ticketsToDoGrouped.find(
      (x) => x.data.name == undefined || false
    );
    groupEmpty.data.name = null;
    groupEmpty.data['visible'] = true;
    this.ticketsToDoGrouped.unshift(groupEmpty);
    this.ticketsToDoGrouped.splice(indexGroupEmpty + 1, 1);

    if (
      addGroupsWithoutToDos &&
      this.groupByType == TicketsGroupByTypes.Group
    ) {
      this.addGroupsWithoutToDos();
      this.setTicketGroupsVisibility();
    }
    this.setTicketOrderInsideGroups();
  }

  public setTicketOrderInsideGroups() {
    this.ticketsToDoGrouped.forEach((group) => {
      if (group.data['orderGroup'] != null) {
        const auxOrder: TicketGroupOrderBy[] = group.data['orderGroup'].filter(
          (x) => x['active'] == true
        );

        if (auxOrder != null) {
          auxOrder.sort((a, b) => a['order'] - b['order']);
          group.children.sort(this.dynamicSort(auxOrder));
        }
      }
    });
  }

  public dynamicSort(
    ticketGroupOrders: TicketGroupOrderBy[]
  ): (a: any, b: any) => number {
    return (a, b) => {
      for (const ticketGroupOrder of ticketGroupOrders) {
        if (ticketGroupOrder.orderType == OrderTypes.Ascending) {
          if (
            a.data[ticketGroupOrder.propertyName] == null &&
            b.data[ticketGroupOrder.propertyName] != null
          ) {
            return 1;
          }
          if (
            a.data[ticketGroupOrder.propertyName] != null &&
            b.data[ticketGroupOrder.propertyName] == null
          ) {
            return -1;
          }
          if (
            a.data[ticketGroupOrder.propertyName] <
            b.data[ticketGroupOrder.propertyName]
          )
            return -1;
          if (
            a.data[ticketGroupOrder.propertyName] >
            b.data[ticketGroupOrder.propertyName]
          )
            return 1;
        } else {
          if (
            a.data[ticketGroupOrder.propertyName] == null &&
            b.data[ticketGroupOrder.propertyName] != null
          ) {
            return -1;
          }
          if (
            a.data[ticketGroupOrder.propertyName] != null &&
            b.data[ticketGroupOrder.propertyName] == null
          ) {
            return 1;
          }
          if (
            a.data[ticketGroupOrder.propertyName] <
            b.data[ticketGroupOrder.propertyName]
          )
            return 1;
          if (
            a.data[ticketGroupOrder.propertyName] >
            b.data[ticketGroupOrder.propertyName]
          )
            return -1;
        }
      }
      return 0; // Objects are equal in terms of sorting
    };
  }

  public async getTicketsGroups() {
    try {
      const response = await this.apiService.get(
        'ticketGroup/minimalList',
        null,
        new InterceptorConfig({
          apiRequest: true,
        })
      );
      if (response?.entity != null) {
        this.optionsTicketsGroups = response.entity;
        //Sort options ticket groups alphabetically
        this.optionsTicketsGroups.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        // populate visible tickets groups columns
        this.ticketsToDoGrouped.forEach((x) => {
          if (x.data.name != null) {
            this.ticketsGroupsColumnsVisible.push(x.data.name);
          }
        });
        this.addGroupsWithoutToDos();
      }
    } catch (e) {
      console.log(e);
    }
  }

  public setTicketGroupsVisibility() {
    this.ticketsToDoGrouped.forEach((x) => {
      const auxTicketGroup = this.ticketsGroupsColumnsVisible.find(
        (columnVisible) => columnVisible == x.data.name
      );

      if (auxTicketGroup) {
        x.data.visible = true;
      } else if (x.data.name != null) {
        x.data.visible = false;
      }
    });
    this.ticketsGroupsColumnsVisible = [...this.ticketsGroupsColumnsVisible];
  }

  public addGroupsWithoutToDos() {
    this.optionsTicketsGroups.forEach((x) => {
      if (!this.ticketsToDoGrouped.find((group) => group.data.name == x.id)) {
        this.ticketsToDoGrouped.push({
          data: {
            name: x.id,
            visible: false,
            displayName: x.name,
            orderGroup: this.arrayUtilityService.clone(this.ticketOrder),
          },
          children: [],
          expanded: true,
        });
      }
    });
  }

  dragStart(x: any): void {
    if (x != null) {
      this.draggedComponent = this.objectsUtilityService.clone(x);
    }
  }

  async dropOnEmptyColumn(
    event: any,
    targetGroup: TreeNode<any>
  ): Promise<void> {
    if (targetGroup != null && this.draggedComponent != null) {
      const indexTargetGroup = this.ticketsToDoGrouped.findIndex(
        (tree) => tree.data.name == targetGroup.data.name
      );

      if (
        indexTargetGroup != -1 &&
        this.draggedComponent[this.groupByType] != targetGroup.data.name
      ) {
        const auxGroup = this.ticketsToDoGrouped.find(
          (x) => x.data.name == this.draggedComponent[this.groupByType]
        );
        const indexToDelete = auxGroup.children.findIndex(
          (p) => p.data.name == this.draggedComponent.name
        );

        auxGroup.children.splice(indexToDelete, 1);
        this.draggedComponent[this.groupByType] = targetGroup.data.name;
        /* this.draggedComponent.data.order = 1;*/
        this.ticketsToDoGrouped[indexTargetGroup].children.push({
          data: { ...this.draggedComponent },
        });
      }
    }

    await this.updateToDoTicket({
      ticketId: this.draggedComponent.id,
      value: this.draggedComponent.ticketGroupId,
      field: this.groupByType,
    });
    (<HTMLElement>event.target).classList.remove('container-drop-on-hoover');
    (<HTMLElement>event.target).classList.add('container-drop');

    this.draggedComponent = null;
  }

  public async updateToDoTicket(updateTicketProperty: UpdateTicketProperty) {
    try {
      const response = await this.ticketService.updateTicketPropertyAsync(
        updateTicketProperty
      );

      if (response != null && response.entity != null) {
        const propertiesToUpdate = [updateTicketProperty.field];
        if (updateTicketProperty.field == 'checklist') {
          propertiesToUpdate.push('ticketStateId');
        }
        if (propertiesToUpdate.find((x) => x == 'ticketStateId')) {
          propertiesToUpdate.push(
            ...[
              'acknowledgmentTimeInSeconds',
              'interventionTimeInSeconds',
              'responseTimeInSeconds',
              'resolutionTimeInSeconds',
              'totalTimeInSeconds',
              'startedOn',
              'endedOn',
              'openOn',
            ]
          );
        }

        this.updateViewObjects(propertiesToUpdate, response.entity);
      }
    } catch (e) {
      console.log(e);
    }
  }

  onDragEnter(event: any, columnEmpty = false): void {
    if (!columnEmpty) {
      (<HTMLElement>event.target).classList.remove('container-line-drop');
      (<HTMLElement>event.target).classList.add(
        'container-line-drop-on-hoover'
      );
    } else {
      (<HTMLElement>event.target).classList.remove('container-drop');
      (<HTMLElement>event.target).classList.add('container-drop-on-hoover');
    }
  }

  async dropBetweenCards(
    event: DragEvent,
    card: any,
    atStartOfCards: boolean
  ): Promise<void> {
    if (card != null && this.draggedComponent != null) {
      if (this.draggedComponent.id == card.data.id) {
        (<HTMLElement>event.target).classList.remove(
          'container-line-drop-on-hoover'
        );
        (<HTMLElement>event.target).classList.add('container-line-drop');

        this.draggedComponent = null;
        return;
      }
      const targetGroupIndex = this.ticketsToDoGrouped.findIndex(
        (group) => group.data.name == card.data[this.groupByType]
      );
      const sourceGroup = this.ticketsToDoGrouped.find(
        (groupToInsert) =>
          groupToInsert.data.name == this.draggedComponent[this.groupByType]
      );

      if (targetGroupIndex != -1 && sourceGroup != null) {
        const indexToDelete = sourceGroup.children.findIndex(
          (group) => group.data.id == this.draggedComponent.id
        );
        if (indexToDelete != -1) {
          this.draggedComponent[this.groupByType] = card.data[this.groupByType];
          sourceGroup.children.splice(indexToDelete, 1);
          if (atStartOfCards) {
            this.ticketsToDoGrouped[targetGroupIndex].children.unshift({
              data: { ...this.draggedComponent },
            });
          } else {
            const auxIndex = this.ticketsToDoGrouped[
              targetGroupIndex
            ].children.findIndex((x) => x.data.id == card.data.id);
            if (auxIndex != -1) {
              this.ticketsToDoGrouped[targetGroupIndex].children.splice(
                auxIndex + 1,
                0,
                {
                  data: {
                    ...this.draggedComponent,
                  },
                }
              );
            }
          }
        }
      }
    }
    (<HTMLElement>event.target).classList.remove(
      'container-line-drop-on-hoover'
    );
    (<HTMLElement>event.target).classList.add('container-line-drop');
    await this.updateToDoTicket({
      ticketId: this.draggedComponent.id,
      value: this.draggedComponent.ticketGroupId,
      field: this.groupByType,
    });
    this.draggedComponent = null;
  }

  onDragLeave(event: DragEvent, columnEmpty = false): void {
    if (!columnEmpty) {
      (<HTMLElement>event.target).classList.remove(
        'container-line-drop-on-hoover'
      );
      (<HTMLElement>event.target).classList.add('container-line-drop');
    } else {
      (<HTMLElement>event.target).classList.remove('container-drop-on-hoover');
      (<HTMLElement>event.target).classList.add('container-drop');
    }
  }

  openDialogTicket(event: EditCardClickObject): void {
    let popupTitle: string;
    if (event?.ticket.id != null) {
      popupTitle = this.translateService.instant(
        'pages.tickets.components.ticket-form.update-ticket.label'
      );
    } else {
      popupTitle = this.translateService.instant(
        'pages.tickets.components.ticket-form.new-ticket.label'
      );
    }

    this.ticketFormRef = this.dialogService.openDialog(TicketFormComponent, {
      header: popupTitle,
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-ticket-dialog-form',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1200px' },
      data: {
        entity: event.ticket,
        showNotes: true,
        defaultTabKey: event.defaultTabKey,
      },
    });

    this.ticketFormRef.onClose.pipe(first()).subscribe(async (value) => {
      this.scrollPositions.clear();
      if (
        value == null ||
        value.data.baseAction.key == 'edit' ||
        value.data.baseAction.key == 'cancel' ||
        value.data.baseAction.key == 'create'
      ) {
        this.getContainerScrollPositions();
      }

      await this.getToDoTickets(this.ticketFilters, true);
      this.setContainerScrollPositions();

      this.ticketFormRef.destroy();
    });
  }

  private setContainerScrollPositions() {
    if (this.scrollPositions != null && this.scrollPositions.size > 0) {
      setTimeout(() => {
        this.scrollPositions.forEach((item, key) => {
          if (item != null && item != 0) {
            const scrollableElement = document.querySelector(
              `.main-container-content-group-ticket.scrollable-element[data-guid="${key}"]`
            );
            if (scrollableElement != null) {
              scrollableElement.scrollTo({
                top: item,
              });
            }
          }
        });
      }, 10);
    }
  }

  private getContainerScrollPositions() {
    if (this.ticketsGroupsColumnsVisible?.length) {
      this.ticketsGroupsColumnsVisible.forEach((x) => {
        const scrollableElement = document.querySelector(
          `.main-container-content-group-ticket.scrollable-element[data-guid="${x}"]`
        );
        if (scrollableElement != null) {
          this.scrollPositions.set(x, scrollableElement.scrollTop);
        }
      });
    }
  }

  onAddNewTask(group: TreeNode<any>): void {
    const newTicket = new Ticket({
      ticketTypeId: TicketTypes.Todo,
      isPublic: false,
    });
    if (group != null && group.data.name != null) {
      newTicket[this.groupByType] = group.data.name;
    }
    this.openDialogTicket({ ticket: newTicket, defaultTabKey: null });
  }

  onChangeGroupBy(): void {
    this.groupByAndSort(true);
  }

  onChangeVisibleGroups(event: any): void {
    const aux = this.ticketsToDoGrouped.find(
      (x) => x.data.name == event.itemValue
    );
    aux.data.visible = !aux.data.visible;
  }

  async onUpdateProperty(event: UpdateTicketProperty): Promise<void> {
    if (event.field != 'ticketUsers') {
      await this.updateToDoTicket(event);
    } else {
      await this.updateTicketAssignUsers(event);
    }
    this.getOptionsForFiltersPresentOnTickets();
  }

  public async updateTicketAssignUsers(
    updateTicketProperty: UpdateTicketProperty
  ) {
    try {
      const response = await this.ticketService.updateTicketAssignUsers(
        updateTicketProperty
      );
      if (response != null && response.entity != null) {
        this.updateViewObjects([updateTicketProperty.field], response.entity);
      }
    } catch (e) {
      console.log(e);
    }
  }

  public updateViewObjects(propertyNames: string[], entity: Ticket) {
    this.ticketsToDoGrouped.forEach((x) => {
      const ticketToUpdateGrouped = x.children.find(
        (x) => x.data.id == entity.id
      );
      if (ticketToUpdateGrouped != null) {
        propertyNames.forEach((propertyName) => {
          ticketToUpdateGrouped.data[propertyName] = entity[propertyName];
        });
        const groupName = this.optionsTicketsGroups.find(
          (x) => x.id == ticketToUpdateGrouped.data['ticketGroupId']
        )?.name;
        ticketToUpdateGrouped.data['ticketGroupName'] =
          groupName != null ? groupName : null;
        ticketToUpdateGrouped.data = { ...ticketToUpdateGrouped.data };
      }
    });
    const ticketToUpdate = this.ticketsToDo.find((x) => x.id == entity.id);
    if (ticketToUpdate != null) {
      propertyNames.forEach((propertyName) => {
        ticketToUpdate[propertyName] = entity[propertyName];
      });
      const groupName = this.optionsTicketsGroups.find(
        (x) => x.id == ticketToUpdate.ticketGroupId
      )?.name;
      ticketToUpdate['ticketGroupName'] = groupName != null ? groupName : null;
    }
    this.setTicketOrderInsideGroups();
  }

  async onDeleteCard(id: string): Promise<void> {
    try {
      const response = await this.ticketService.deleteTicketAsync(id);

      if (response) {
        const ticketToDeleteIndex = this.ticketsToDo.findIndex(
          (x) => x.id == id
        );
        if (ticketToDeleteIndex != -1) {
          this.ticketsToDo.splice(ticketToDeleteIndex, 1);
        }

        this.ticketsToDoGrouped.every((group) => {
          const ticketGroupedToDeleteIndex = group.children.findIndex(
            (ticket) => ticket.data.id == id
          );

          if (ticketGroupedToDeleteIndex != -1) {
            group.children.splice(ticketGroupedToDeleteIndex, 1);
            return null;
          } else {
            return true;
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async onOutputFilters(filters: Filter[]): Promise<void> {
    const filtersWithTicketType = filters.filter(
      (x) => x.propertyName == 'TicketTypeId'
    );
    const previousFiltersWithTicketType = this.ticketFilters.filter(
      (x) => x.propertyName == 'TicketTypeId'
    );
    let ticketTypeFilterHasChanged: boolean = false;
    if (filtersWithTicketType.length != previousFiltersWithTicketType.length) {
      ticketTypeFilterHasChanged = true;
    } else {
      filtersWithTicketType.every((x) => {
        const y = this.ticketFilters.find(
          (y) => y.propertyName == 'TicketTypeId' && y.value == x.value
        );
        if (y == null) {
          ticketTypeFilterHasChanged = true;
          return null;
        } else {
          return true;
        }
      });
    }
    this.ticketFilters = this.arrayUtilityService.clone(filters);
    if (ticketTypeFilterHasChanged) {
      this.ticketStateFilters = [];
      this.selectedFilterTicketState = null;
    } else {
      this.ticketFilters.push(...this.ticketStateFilters);
    }

    await this.getToDoTickets(this.ticketFilters, this.onInit ? false : true);
    if (this.onInit) {
      await this.getTicketsGroups();
      this.onInit = false;
    }
    await this.getFilterOptionsForTicketStates();

    this.setTicketPreferencesOnStorage();
  }

  private getOptionsForFiltersPresentOnTickets(): void {
    //Park Options
    let parks = this.ticketsToDo.map((x) => {
      return {
        id: x?.rootResourceId,
        label: x['rootResourceName'],
      };
    });

    parks = parks.filter((x) => x.id != null);
    if (parks != null && parks.length > 0) {
      parks = [...new Map(parks.map((m) => [m.id, m])).values()];
      const filterRootResourceId = this.filterMenu.find(
        (x) => x.propertyName == 'RootResourceId'
      );

      parks.forEach((x) => {
        const aux = filterRootResourceId.items.find((y) => y.value == x.id);
        if (aux == null) {
          filterRootResourceId.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        }
      });
      filterRootResourceId.items.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
    //Client Options
    let clients = this.ticketsToDo.map((x) => {
      return {
        id: x?.clientId,
        label: x['clientName'],
      };
    });

    clients = clients.filter((x) => x.id != null);
    if (clients != null && clients.length > 0) {
      clients = [...new Map(clients.map((m) => [m.id, m])).values()];
      const filterClientId = this.filterMenu.find(
        (x) => x.propertyName == 'ClientId'
      );

      clients.forEach((x) => {
        const aux = filterClientId.items.find((y) => y.value == x.id);
        if (aux == null) {
          filterClientId.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        }
      });
      filterClientId.items.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
    //User Options
    let users = [];
    this.ticketsToDo.map((x) => {
      if (x.ticketUsers && x.ticketUsers.length > 0) {
        x.ticketUsers.forEach((y) => {
          users.push({
            userId: y.userId,
            label: y.name,
          });
        });
      }
    });
    if (users.length > 0) {
      users = [...new Map(users.map((m) => [m.userId, m])).values()];
      const filterTicketUsers = this.filterMenu.find(
        (x) => x.propertyName == 'TicketUsers[UserId]'
      );
      users.forEach((x) => {
        const aux = filterTicketUsers.items.find((y) => y.value == x.userId);
        if (aux == null) {
          filterTicketUsers.items.push(
            new PrgFilterMenuItemChild({
              value: x.userId,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        }
      });
      filterTicketUsers.items.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
    //Tag Options
    let tags = [];
    this.ticketsToDo.map((x) => {
      if (x.tags && x.tags.length > 0) {
        x.tags.forEach((y) => {
          tags.push({
            id: y.id,
            label: y.name,
          });
        });
      }
    });
    if (tags.length > 0) {
      tags = [...new Map(tags.map((m) => [m.id, m])).values()];
      const filterTags = this.filterMenu.find(
        (x) => x.propertyName == 'Tags[Id]'
      );
      tags.forEach((x) => {
        const aux = filterTags.items.find((y) => y.value == x.id);
        if (aux == null) {
          filterTags.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        }
      });
      filterTags.items.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
  }

  public async getFilterOptionsForTicketStates() {
    this.optionsForTicketState = [];
    const ticketTypesFilters = this.ticketFilters.filter(
      (x) => x.propertyName == 'TicketTypeId'
    );

    if (ticketTypesFilters != null && ticketTypesFilters.length == 1) {
      this.showGlobalFilterTicketState = false;
      const stateModelName = 'TicketStateModel_' + ticketTypesFilters[0]?.value;
      let stateModel: StateModel;

      if (this.mapStateModels.get(stateModelName) != null) {
        stateModel = this.mapStateModels.get(stateModelName);
      } else {
        stateModel = await this.stateModelsService.getStateModelByName(
          stateModelName
        );
        this.mapStateModels.set(stateModelName, stateModel);
      }
      if (
        stateModel != null &&
        stateModel.states != null &&
        stateModel.states.length > 0
      ) {
        stateModel.states.forEach((state) => {
          this.optionsForTicketState.push({
            id: state.referenceElementId,
            label: this.translateService.instant(
              'lookup-tables.' +
                state.referenceElementId.split('.')[0] +
                '.items.' +
                state.referenceElementId +
                '.label'
            ),
          });
        });
      }
    } else {
      this.showGlobalFilterTicketState = true;
      this.optionsForTicketState = [
        {
          id: 'allOpen',
          label: this.translateService.instant(
            this.basePageTranslationPath +
              'filter-options.ticketstate.allopen.label'
          ),
        },
        {
          id: 'allClose',
          label: this.translateService.instant(
            this.basePageTranslationPath +
              'filter-options.ticketstate.allclose.label'
          ),
        },
      ];
    }
    this.optionsForTicketState.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    this.setTicketPreferencesOnStorage();
  }

  async onChangeGlobalFilterTicketState(
    event: any,
    reloadData: boolean = true
  ): Promise<void> {
    this.ticketStateFilters = [];
    this.ticketFilters = this.ticketFilters.filter(
      (x) => x.propertyName != 'TicketStateId'
    );
    if (event != null && event.value != null && event.value?.length) {
      const stateModelsNames: string[] = [];
      this.ticketTypesItems.forEach((x) => {
        stateModelsNames.push('TicketStateModel_' + x.id);
      });
      for (const name of stateModelsNames) {
        const index = stateModelsNames.indexOf(name);
        let stateModel: StateModel;
        if (this.mapStateModels.get(name) != null) {
          stateModel = this.mapStateModels.get(name);
        } else {
          stateModel = await this.stateModelsService.getStateModelByName(name);
          this.mapStateModels.set(name, stateModel);
        }
        stateModel.endStates.forEach((x, innerIndex) => {
          this.ticketStateFilters.push(
            new Filter({
              startGroup: index == 0,
              propertyName: 'TicketStateId',
              value: x.referenceElementId,
              filterOperation:
                event.value == 'allClose'
                  ? FilterOperations.EqualTo
                  : FilterOperations.NotEqualTo,
              filterExpression:
                (index == stateModelsNames.length - 1 &&
                  stateModel.endStates.length - 1 == innerIndex) ||
                event.value == 'allOpen'
                  ? FilterExpressions.And
                  : FilterExpressions.Or,
            })
          );
        });
      }
    }
    this.ticketFilters.push(...this.ticketStateFilters);
    if (reloadData) {
      await this.getToDoTickets(this.ticketFilters, true);
    }
    this.setTicketPreferencesOnStorage();
  }

  async onChangeFilterTicketState(
    event: any,
    reloadData: boolean = true
  ): Promise<void> {
    this.ticketStateFilters = [];
    this.ticketFilters = this.ticketFilters.filter(
      (x) => x.propertyName != 'TicketStateId'
    );
    if (event != null && event.value != null && event.value.length > 0) {
      event.value.forEach((value, index) => {
        this.ticketStateFilters.push(
          new Filter({
            startGroup: index == 0,
            propertyName: 'TicketStateId',
            value: value,
            filterOperation: FilterOperations.EqualTo,
            filterExpression:
              index == event.value.length - 1
                ? FilterExpressions.And
                : FilterExpressions.Or,
          })
        );
      });
    }
    this.ticketFilters.push(...this.ticketStateFilters);
    if (reloadData) {
      await this.getToDoTickets(this.ticketFilters, true);
    }
    this.setTicketPreferencesOnStorage();
  }

  onChangeSortField(
    orderField: TicketGroupOrderBy,
    orderGroups: TicketGroupOrderBy[]
  ): void {
    if (orderField.active) {
      if (orderField.orderType == OrderTypes.Descending) {
        orderField.active = false;
        orderField.orderType = null;
        const aux = orderGroups.filter((x) => x.order > orderField.order);
        if (aux != null && aux.length > 0) {
          aux.forEach((x) => {
            x.order = x.order - 1;
          });
        }
        orderField.order = null;
      } else {
        orderField.orderType = OrderTypes.Descending;
      }
    } else {
      orderField.active = true;
      orderField.orderType = OrderTypes.Ascending;
      orderField.order = this.getMaxOrderIndex(orderGroups) + 1;
    }

    this.setTicketOrderInsideGroups();
    this.setTicketPreferencesOnStorage();
  }

  onChangeGlobalSortField(
    orderField: TicketGroupOrderBy,
    orderGroups: TicketGroupOrderBy[]
  ): void {
    if (orderField.active) {
      if (orderField.orderType == OrderTypes.Descending) {
        orderField.active = false;
        orderField.orderType = null;
        const aux = orderGroups.filter((x) => x.order > orderField.order);
        if (aux != null && aux.length > 0) {
          aux.forEach((x) => {
            x.order = x.order - 1;
          });
        }
        orderField.order = null;
      } else {
        orderField.orderType = OrderTypes.Descending;
      }
    } else {
      orderField.active = true;
      orderField.orderType = OrderTypes.Ascending;
      orderField.order = this.getMaxOrderIndex(orderGroups) + 1;
    }
    this.ticketsToDoGrouped.forEach((x) => {
      x.data['orderGroup'] = this.arrayUtilityService.clone(orderGroups);
    });

    this.setTicketOrderInsideGroups();
    this.setTicketPreferencesOnStorage();
  }

  public getMaxOrderIndex(array: TicketGroupOrderBy[]): number | undefined {
    if (array.length === 0) {
      return undefined; // or throw an error, depending on your requirements
    }

    return array.reduce(
      (max, obj) => (obj.order > max ? obj.order : max),
      array[0].order
    );
  }

  onClearGlobalOrder(): void {
    this.ticketOrder.forEach((x) => {
      x.order = null;
      x.orderType = null;
      x.active = false;
    });
    this.ticketsToDoGrouped.forEach((x) => {
      x.data['orderGroup'] = this.arrayUtilityService.clone(this.ticketOrder);
    });
    this.setTicketOrderInsideGroups();
    this.setTicketPreferencesOnStorage();
  }

  onClearOrder(orderGroups: TicketGroupOrderBy[]): void {
    orderGroups.forEach((x) => {
      x.order = null;
      x.orderType = null;
      x.active = false;
    });
    this.setTicketOrderInsideGroups();
    this.setTicketPreferencesOnStorage();
  }

  async openDialogWorkOrder(event: Ticket): Promise<void> {
    if (this.entityTypeWorkOrder == null) {
      this.entityTypeWorkOrder =
        await this.entityTypeService.getAllEntityTypeDataByName('WorkOrder');
    }
    this.newWorkOrder = new WorkOrder({
      genericRelation: new GenericRelation({
        sourceId: event.id,
        sourceEntityType: 'Ticket',
        targetEntityType: 'WorkOrder',
      }),
      isRecurring: false,
      recurrence: null,
      description: event.description,
      name: event.name,
      resourceId: event?.rootResourceId != null ? event.rootResourceId : null,
    });
    if (event.dueDate != null && event.dueDate != '') {
      this.newWorkOrder.plannedFinishDate = this.setDateTimeToEndOfDay(
        new Date(this.utilityService.getIsoStringInLocalTime(event.dueDate))
      );
    }

    this.displayWorkOrderModal = true;
  }

  private setDateTimeToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 0));
  }

  onExecutedAction(event: ExecutedAction): void {
    this.newWorkOrder = null;
    this.displayWorkOrderModal = null;
  }
  onHideDialog(): void {
    this.newWorkOrder = null;
  }

  onCreateTicket(event: Ticket): void {
    const newTicket: Ticket = new Ticket({
      genericRelation: new GenericRelation({
        sourceId: event.id,
        sourceEntityType: 'Ticket',
        targetEntityType: 'Ticket',
      }),
      ticketPriorityId: event.ticketPriorityId,
      ticketGroupId: event.ticketGroupId != null ? event.ticketGroupId : null,
      rootResourceId:
        event.rootResourceId != null ? event.rootResourceId : null,
      resourceId: event.resourceId != null ? event.resourceId : null,
      isPublic: false,
      description: event.description,
      ticketUsers:
        event.ticketUsers != null && event.ticketUsers.length > 0
          ? event.ticketUsers
          : [],
    });
    if (event.dueDate != null && event.dueDate != '') {
      newTicket.dueDate = event.dueDate;
    }
    this.openDialogTicket({ ticket: newTicket, defaultTabKey: null });
  }

  private setUserPermissions() {
    if (this.departmentService.currentUserIsDepartmentManager()) {
      this.departmentManagerDepartments =
        this.departmentService.getUserDepartmentsValue();
      if (this.departmentManagerDepartments != null) {
        this.userCanCreateTicket =
          this.authGuard.isGranted('create', 'ticket') &&
          this.departmentManagerDepartments.some(
            (x) => x.canCreateTicket == true
          );
      } else {
        this.userCanCreateTicket = false;
      }
    }
  }

  async onCloneTicket(event: Ticket): Promise<void> {
    try {
      const response = await this.apiService.post(
        `Ticket/CloneTicket/${event.id}`,
        null,
        new InterceptorConfig({
          apiRequest: true,
        })
      );
      if (response != null) {
        this.scrollPositions.clear();
        this.getContainerScrollPositions();
        await this.getToDoTickets(this.ticketFilters, true);
        this.setContainerScrollPositions();
      }
    } catch (e) {}
  }
}

export const STORAGE_TICKET_PREFERENCES_KEY: string = 'ticketPreferences';
