import {
  FilterOperations,
  PrgFilterMenuItem,
  PrgFilterMenuItemChild,
} from '@prg/prg-core-lib';

export const CalendarFilters: PrgFilterMenuItem[] = [
  new PrgFilterMenuItem({
    propertyName: 'SentToClient',
    expanded: false,
    label: 'pages.work-orders.filters-options.senttoclient.label',
    translatedItem: true,
    multipleSelection: false,
    icon: 'pi pi-envelope',
    items: [
      new PrgFilterMenuItemChild({
        filterOperation: FilterOperations.EqualTo,
        value: 'false',
        label: 'pages.work-orders.filters-options.senttoclient.no.label',
        translatedItem: true,
        active: false,
      }),
      new PrgFilterMenuItemChild({
        filterOperation: FilterOperations.EqualTo,
        value: 'true',
        label: 'pages.work-orders.filters-options.senttoclient.yes.label',
        translatedItem: true,
        active: false,
      }),
    ],
  }),
  new PrgFilterMenuItem({
    propertyName: 'WorkOrderTypeId',
    expanded: false,
    label: 'entities.workorder.fields.workordertypeid.label',
    translatedItem: true,
    multipleSelection: false,
    icon: 'pi pi-calendar',
    items: [],
  }),
  new PrgFilterMenuItem({
    propertyName: 'Tags[Id]',
    expanded: false,
    label: 'entities.workorder.fields.tags.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-tags',
    items: [],
  }),
];
