import { Injectable } from '@angular/core';
import {
  AbstractAuthService,
  ApiGenericService,
  FilterGroup,
  InterceptorConfig,
  ResultObject,
  UtilityService,
} from '@prg/prg-core-lib';
import { UpdateTicketProperty } from '../../kanban-board/components/kanban-card/kanban-card.component';
import { RoleNames } from '../../Utilities/helpers';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private baseUrl: string = 'ticket';

  constructor(
    private apiGenericService: ApiGenericService,
    private authService: AbstractAuthService,
    private utilityService: UtilityService
  ) {}

  public getTickets(filters: FilterGroup): Promise<ResultObject> {
    const clientUrl = '/clienttickets';
    const managerUrl = '/ticketsfiltered';
    let url = this.baseUrl + managerUrl;
    if (
      this.authService
        .getLoggedUser()
        .workspaceTokens?.token?.role.includes(RoleNames.Client)
    ) {
      url = this.baseUrl + clientUrl;
    }

    return new Promise<ResultObject>((resolve, reject) => {
      this.apiGenericService
        .post(
          url,
          filters,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            resolve(response);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public updateTicketPropertyAsync(
    updateTicketProperty: UpdateTicketProperty
  ): Promise<ResultObject> {
    return new Promise((resolve, reject) => {
      const propertyNameUpper = this.utilityService.capitalizeFirstLetter(
        updateTicketProperty.field
      );
      const updateTicket = {
        [propertyNameUpper]: updateTicketProperty.value,
      };
      this.apiGenericService
        .patch(
          this.utilityService.normalizeUrl(this.baseUrl, 'updateProperty'),
          updateTicket,
          updateTicketProperty.ticketId,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: false,
          })
        )
        .then(
          (response) => {
            resolve(response);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public updateTicketAssignUsers(
    updateTicketProperty: UpdateTicketProperty
  ): Promise<ResultObject> {
    return new Promise((resolve, reject) => {
      this.apiGenericService
        .put(
          this.utilityService.normalizeUrl(this.baseUrl, 'AssignTicketUsers'),
          updateTicketProperty.value,
          updateTicketProperty.ticketId,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: false,
          })
        )
        .then(
          (response) => {
            resolve(response);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }
  public deleteTicketAsync(ticketId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiGenericService
        .delete(
          this.utilityService.normalizeUrl(this.baseUrl, 'delete'),
          ticketId,
          new InterceptorConfig({
            apiRequest: true,
          })
        )
        .then(
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
    });
  }
}
