import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  PageContextService,
  PrgCoreModule,
  PrgEntityTypesModule,
  PrgSharedComponentsModule,
} from '@prg/prg-core-lib';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { ListboxModule } from 'primeng/listbox';
import { PaginatorModule } from 'primeng/paginator';
import { TagModule } from 'primeng/tag';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { WorkOrdersCommonModule } from '../work-orders/work-orders-common.module';
import { DashboardAuxComponent } from './components/dashboard-aux/dashboard-aux.component';
import { TicketCardComponent } from './components/ticket-list/components/ticket-card/ticket-card.component';
import { TicketFormComponent } from './components/ticket-list/components/ticket-form/ticket-form.component';
import { TicketsFiltersComponent } from './components/ticket-list/components/tickets-filters/tickets-filters.component';
import { TicketsListComponent } from './components/ticket-list/tickets-list.component';
import { TicketRoutingModule } from './ticket-routing.module';

@NgModule({
  declarations: [
    DashboardAuxComponent,
    TicketsListComponent,
    TicketCardComponent,
    TicketFormComponent,
    TicketsFiltersComponent,
  ],
  imports: [
    ToolbarModule,
    ListboxModule,
    PaginatorModule,
    TranslateModule,
    TagModule,
    ButtonModule,
    CommonModule,
    DynamicDialogModule,
    PrgCoreModule,
    EditorModule,
    CardModule,
    PrgEntityTypesModule,
    TicketRoutingModule,
    TooltipModule,
    DialogModule,
    WorkOrdersCommonModule,
    PrgSharedComponentsModule,
  ],
  providers: [PageContextService],
  exports: [TicketFormComponent],
})
export class TicketModule {}
