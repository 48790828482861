import { Injectable } from '@angular/core';

import {
  ApiGenericService,
  FilterGroup,
  InterceptorConfig,
  UtilityService,
} from '@prg/prg-core-lib';
import { WorkOrder } from '../models/work-order-model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersService {
  constructor(
    private utilityService: UtilityService,
    private apiGenericService: ApiGenericService
  ) {}

  public getWorkOrdersByDateIntervalAsync(
    startDate: Date,
    endDate: Date,
    resourceIds: string[] = [],
    workOrderStatesSelected: string[] = [],
    usersIds: string[] = [],
    filterGroup: FilterGroup = null
  ): Promise<WorkOrder[]> {
    return new Promise<WorkOrder[]>((resolve, reject) => {
      this.apiGenericService
        .post(
          'workorder/getworkordersbydateintervalminimal/' +
            this.utilityService.getIsoStringInLocalTime(startDate) +
            '/' +
            this.utilityService.getIsoStringInLocalTime(endDate),
          {
            resourceIds: resourceIds,
            statesIds: workOrderStatesSelected,
            usersIds: usersIds,
            filterGroup: filterGroup,
          },
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            response.entity.forEach((workOrder: WorkOrder) => {
              workOrder.plannedStartDate = new Date(workOrder.plannedStartDate);
              workOrder.plannedFinishDate = new Date(
                workOrder.plannedFinishDate
              );
            });

            resolve(response.entity);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public updateWorkOrderAsync(workOrder: WorkOrder): Promise<WorkOrder> {
    {
      return new Promise<WorkOrder>((resolve, reject) => {
        this.apiGenericService
          .put(
            'workorder',
            workOrder,
            workOrder.id,
            new InterceptorConfig({
              apiRequest: true,
              handleLoading: true,
              handleErrors: true,
            })
          )
          .then(
            (response) => {
              response.entity.startDate = new Date(response.entity.startDate);
              response.entity.endDate = new Date(response.entity.endDate);

              resolve(response.entity);
            },
            (reason) => {
              reject(reason);
            }
          );
      });
    }
  }

  public updateWorkOrderToNewDatesAsync(
    workOrderId: string,
    newStartDate: Date,
    newEndDate: Date
  ): Promise<WorkOrder> {
    {
      return new Promise<WorkOrder>((resolve, reject) => {
        this.apiGenericService
          .put(
            'workorder/updateworkordertonewdates/' +
              workOrderId +
              '/' +
              this.utilityService.getIsoStringInLocalTime(newStartDate) +
              '/' +
              this.utilityService.getIsoStringInLocalTime(newEndDate) +
              '/' +
              false,
            null,
            null,
            new InterceptorConfig({
              apiRequest: true,
              handleLoading: true,
              handleErrors: false,
            })
          )
          .then(
            (response) => {
              response.entity.startDate = new Date(response.entity.startDate);
              response.entity.endDate = new Date(response.entity.endDate);

              resolve(response.entity);
            },
            (reason) => {
              reject(reason);
            }
          );
      });
    }
  }
}
