import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractAuthService,
  MainLayoutService,
  MapComponentTypesToClass,
  PrgConfigHeader,
  PrgConfigSidebarMenu,
  User,
} from '@prg/prg-core-lib';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { DepartmentService } from './Core/services/department-service/department.service';
import { STORAGE_TICKET_PREFERENCES_KEY } from './kanban-board/components/kanban-board.component';
import {
  LANGUAGE_MENU_HEADER,
  SIDEBAR_MENU,
  USER_MENU_HEADER,
} from './menus-items-layout/menus-structure-layout';
import { TicketsListComponent } from './tickets/components/ticket-list/tickets-list.component';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',*/
  template: `
    <ion-app>
      <router-outlet></router-outlet>
    </ion-app>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public mapComponentsType: Map<string, any> = MapComponentTypesToClass;

  public subscriptions: Subscription[] = [];
  private user: User;

  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private router: Router,
    private mainLayoutService: MainLayoutService,
    private authService: AbstractAuthService,
    private departmentService: DepartmentService
  ) {
    this.mainLayoutService.setSidebarConfig(
      new PrgConfigSidebarMenu({
        mainMenuItems: SIDEBAR_MENU,
        showWorkspaceSelectOnSmallScreens: false,
      })
    );

    this.mainLayoutService.setHeaderConfig(
      new PrgConfigHeader({
        menuItemsUser: USER_MENU_HEADER,
        menuItemsLanguage: LANGUAGE_MENU_HEADER,
        showLanguageMenu: false,
        showButtonNotifications: true,
        showWorkspaceSelect: false,
        actionNotificationButton: {
          default: false,
          routerLink: ['/notifications/list'],
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }

  ngOnInit(): void {
    window.console.info('Application Version: ', environment.version);

    this.mapComponentsType.set('ticket-list-card', TicketsListComponent);
    this.setupTranslateService();
    const config = this.router.config;
    config.push({
      path: '**',
      redirectTo: '',
      pathMatch: 'full',
    });
    this.router.resetConfig(config);

    this.subscriptions.push(
      this.authService.getLoggedUserObservable().subscribe(async (user) => {
        this.user = user;

        if (this.user == null) {
          localStorage.removeItem(STORAGE_TICKET_PREFERENCES_KEY);
        }

        if (
          this.user != null &&
          this.departmentService.currentUserIsDepartmentManager()
        ) {
          const departments =
            await this.departmentService.getDepartmentsByUserIdAsync(
              this.user.id
            );
          this.departmentService.setUserDepartments(departments);
        } else {
          this.departmentService.setUserDepartments(null);
        }
      })
    );
  }

  private setupTranslateService() {
    /// Configure translation service
    /// {
    // TODO: Get languages and default language from configuration

    this.translateService.langs = ['pt', 'en'];
    let currentLanguage = this.translateService.langs.includes(
      navigator.language
    )
      ? navigator.language
      : this.translateService.langs[0];

    const userLanguageSaved = JSON.parse(
      localStorage.getItem('techparts-solar-language-default')
    );
    if (userLanguageSaved != null) {
      currentLanguage = userLanguageSaved;
    }

    this.setUpLanguage(currentLanguage);
  }
  public setUpLanguage(currentLanguage: string) {
    this.translateService.setDefaultLang(currentLanguage);
    this.translateService.use(currentLanguage);
    this.translateService.onLangChange.subscribe((event: any) => {
      localStorage.removeItem('techparts-solar-language-default');
      localStorage.setItem(
        'techparts-solar-language-default',
        JSON.stringify(event?.lang)
      );
      this.translateService.get('primeng').subscribe((res) => {
        this.primeNGConfig.setTranslation(res);
      });
    });
  }
}
