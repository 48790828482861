import { ReferenceType, SelectConfiguration } from '@prg/prg-core-lib';

export const TICKETS_FILTERS = {
  filters: [
    new SelectConfiguration({
      key: 'ticketStateId',
      showClear: false,
      optionLabel: 'label',
      optionValue: 'id',
      appendTo: 'body',
      referenceType: ReferenceType.LookupTable,
      referenceName: 'ticketstates',
      disabledOutputEvent: false,
      basePathTranslation: 'pages.tickets.components.ticket-list.filters',
      orderField: 0,
    }),
    new SelectConfiguration({
      key: 'ticketPriorityId',
      showClear: false,
      optionLabel: 'label',
      optionValue: 'id',
      appendTo: 'body',
      referenceType: ReferenceType.LookupTable,
      referenceName: 'ticketpriorities',
      disabledOutputEvent: false,
      basePathTranslation: 'pages.tickets.components.ticket-list.filters',
      orderField: 0,
    }),
  ],
  order: [
    new SelectConfiguration({
      key: 'orderBy',
      showClear: false,
      optionLabel: 'label',
      optionValue: '',
      appendTo: 'body',
      referenceType: ReferenceType.EntityType,
      referenceName: 'ticketfilter',
      disabledOutputEvent: false,
      basePathTranslation: 'pages.tickets.components.ticket-list.order-filters',
      orderField: 1,
      translateEntityTypeItems: true,
    }),
  ],
};
