import { BaseEntity, GenericRelation, OrderTypes } from '@prg/prg-core-lib';
import { Department } from '../../Core/models/department.model';
import { Tag } from '../../Core/models/tag.model';

export class Ticket extends BaseEntity {
  public rootResourceId?: string;
  public rootResource?: any;
  public resourceId?: string;
  public resource?: any;
  public ticketStateId?: string | TicketStates;
  public ticketPriorityId?: string | TicketPriorities;
  public description?: string;
  public autoNumber?: string;
  public clientId?: string;
  public isPublic?: boolean;
  public ticketOrders?: TicketOrder[];
  public ticketGroup?: TicketGroup;
  public ticketGroupId?: string;
  public ticketTypeId?: TicketTypes | string;
  public ticketUsers?: TicketUser[];
  public dueDate?: Date | string;
  public genericRelation?: GenericRelation | any;
  public checklist?: string;
  public closeTicketOnChecklistCompleted?: boolean;
  public departmentId?: string;
  public department?: Department;
  public tags?: Tag[];
  public alarmCreatedOn?: Date | string;
  public energyLost?: number;
  public startedOn?: Date | string;
  public endedOn?: Date | string;
  public openOn?: Date | string;
  public acknowledgmentTimeInSeconds?: number;
  public interventionTimeInSeconds?: number;
  public responseTimeInSeconds?: number;
  public resolutionTimeInSeconds?: number;
  public totalTimeInSeconds?: number;
  public includeTicketOnKPIs?: boolean = true;
  public useSolarSchedule?: boolean = null;

  /**
   * Constructor
   * @param {Partial<Ticket>} init
   */
  constructor(init?: Partial<Ticket>) {
    super();
    Object.assign(this, init);
  }
}

export enum TicketStates {
  waiting = 'ticketstates.waiting',
  inResolution = 'ticketstates.inresolution',
  resolved = 'ticketstates.resolved',
}

export enum TicketTodoStates {
  waiting = 'tickettodostates.waiting',
  inResolution = 'tickettodostates.inresolution',
  resolved = 'tickettodostates.resolved',
}

export enum TicketPriorities {
  high = 'ticketpriorities.001',
  normal = 'ticketpriorities.002',
  low = 'ticketpriorities.003',
}

export const TICKETS_ORDERS = [
  {
    dataKey: 'priorityLowToHigh',
    property: 'TicketPriorityId',
    keyLabel: 'priority low to high',
    order: OrderTypes.Ascending,
  },
  {
    dataKey: 'priorityHighToLow',
    property: 'TicketPriorityId',
    keyLabel: 'priority high to low',
    order: OrderTypes.Descending,
  },
  {
    dataKey: 'createdOnOlderToNewer',
    property: 'CreatedOn',
    keyLabel: 'created older to newer',
    order: OrderTypes.Ascending,
  },
  {
    dataKey: 'createdOnNewerToOlder',
    property: 'CreatedOn',
    keyLabel: 'created newer to older',
    order: OrderTypes.Descending,
  },
  {
    dataKey: 'modifiedOnNewerToOlder',
    property: 'ModifiedOn',
    keyLabel: 'modifiedOn older to newer',
    order: OrderTypes.Ascending,
  },
  {
    dataKey: 'modifiedOnNewerToOlder',
    property: 'ModifiedOn',
    keyLabel: 'modifiedOn newer to older',
    order: OrderTypes.Descending,
  },
];

export class TicketOrder extends BaseEntity {
  /**
   * Group
   * @type {string}
   */
  public groupBy: string;

  /**
   * Ticket Id
   * @type {string}
   */
  public ticketId: string;

  /**
   * order
   * @type {number}
   */
  public order: number;

  /**
   * Constructor
   * @param {Partial<TicketOrder>} init
   */
  constructor(init?: Partial<TicketOrder>) {
    super();
    Object.assign(this, init);
  }
}

export class TicketGroup extends BaseEntity {
  /**
   * description
   * @type {string}
   */
  public description: string;

  /**
   * order
   * @type {number}
   */
  public order: number;

  /**
   * Constructor
   * @param {Partial<TicketOrder>} init
   */
  constructor(init?: Partial<TicketGroup>) {
    super();
    Object.assign(this, init);
  }
}

export enum TicketTypes {
  Todo = 'tickettypes.todo',
  Ticket = 'tickettypes.ticket',
  Occurrence = 'tickettypes.occurrence',
}

export enum TicketsGroupByTypes {
  Park = 'rootResourceId',
  Client = 'clientId',
  Group = 'ticketGroupId',
}

export class TicketUser extends BaseEntity {
  /**
   * ticket
   * @type {string}
   */
  public ticketId?: string;

  /**
   * user
   * @type {string}
   */
  public userId?: string;

  /**
   * Constructor
   * @param {Partial<TicketUser>} init
   */
  constructor(init?: Partial<TicketUser>) {
    super();
    Object.assign(this, init);
  }
}
