import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
  ApiGenericService,
  DynamicFormContextService,
  UtilityService,
} from '@prg/prg-core-lib';
import {
  Months,
  Recurrence,
  RecurringOptions,
  WeekDays,
} from '../../../user-availabilities/models/user-availability.model';
import { WorkOrder } from '../../models/work-order-model';

@Component({
  selector: 'app-work-order-recurring',
  templateUrl: './work-order-recurring.component.html',
  styleUrls: ['./work-order-recurring.component.scss'],
})
export class WorkOrderRecurringComponent implements OnInit {
  /* @Input() workOrder: WorkOrder;*/
  @Input() formGroup: FormGroup;
  private _workOrder: WorkOrder = null;

  public get workOrder() {
    return this._workOrder;
  }

  private readonly WEEK_DAYS_SEPARATOR = ';';

  public get RecurringOptions() {
    return RecurringOptions;
  }

  public get WeekDays() {
    return WeekDays;
  }

  public recurringOptions = [
    {
      name: this.translateService.instant(
        RecurringOptions.Weekly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Weekly,
    },
    {
      name: this.translateService.instant(
        RecurringOptions.Monthly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Monthly,
    },
    {
      name: this.translateService.instant(
        RecurringOptions.Yearly.toLowerCase() + '.label'
      ),
      value: RecurringOptions.Yearly,
    },
  ];

  public weekDayOptions = [
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Sunday + '.label'
      ),
      value: WeekDays.Sunday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Monday + '.label'
      ),
      value: WeekDays.Monday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Tuesday + '.label'
      ),
      value: WeekDays.Tuesday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Wednesday + '.label'
      ),
      value: WeekDays.Wednesday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Thursday + '.label'
      ),
      value: WeekDays.Thursday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Friday + '.label'
      ),
      value: WeekDays.Friday,
    },
    {
      name: this.translateService.instant(
        'weekdays.' + WeekDays.Saturday + '.label'
      ),
      value: WeekDays.Saturday,
    },
  ];

  public monthOptions = [
    {
      name: this.translateService.instant(
        'months.' + Months.January + '.label'
      ),
      value: Months.January,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.February + '.label'
      ),
      value: Months.February,
    },
    {
      name: this.translateService.instant('months.' + Months.March + '.label'),
      value: Months.March,
    },
    {
      name: this.translateService.instant('months.' + Months.April + '.label'),
      value: Months.April,
    },
    {
      name: this.translateService.instant('months.' + Months.May + '.label'),
      value: Months.May,
    },
    {
      name: this.translateService.instant('months.' + Months.June + '.label'),
      value: Months.June,
    },
    {
      name: this.translateService.instant('months.' + Months.July + '.label'),
      value: Months.July,
    },
    {
      name: this.translateService.instant('months.' + Months.August + '.label'),
      value: Months.August,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.September + '.label'
      ),
      value: Months.September,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.October + '.label'
      ),
      value: Months.October,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.November + '.label'
      ),
      value: Months.November,
    },
    {
      name: this.translateService.instant(
        'months.' + Months.December + '.label'
      ),
      value: Months.December,
    },
  ];

  constructor(
    private translateService: TranslateService,
    private apiGenericService: ApiGenericService,
    private utilityService: UtilityService,
    private dynamicFormContextService: DynamicFormContextService
  ) {}

  /**
   * ngOnInit
   */
  async ngOnInit() {
    this._workOrder = await this.dynamicFormContextService.getVariableValue(
      'workOrder',
      'entity'
    );
    this.workOrder.startDate = null;
  }

  public onIsRecurringChanged(): void {
    if (this.workOrder.isRecurring) {
      this.workOrder.recurrence = new Recurrence({
        recurEvery: 1,
        weekDays: WeekDays.Sunday.toString(),
        weekDaysArray: [WeekDays.Sunday],
        recurringOptionId: RecurringOptions.Weekly,
      });
    }
    this.setVariableOnContext();
  }

  public onRecurringSelectedChanged(): void {
    const recurStartDate = this.workOrder.recurrence.startDate;
    const recurEndDate = this.workOrder.recurrence.endDate;
    switch (this.workOrder.recurrence.recurringOptionId) {
      case RecurringOptions.Weekly:
        this.workOrder.recurrence = new Recurrence({
          recurEvery: 1,
          weekDays: WeekDays.Sunday.toString(),
          weekDaysArray: [WeekDays.Sunday],
          recurringOptionId: RecurringOptions.Weekly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
      case RecurringOptions.Monthly:
        this.workOrder.recurrence = new Recurrence({
          recurEvery: 1,
          monthDay: 1,
          recurringOptionId: RecurringOptions.Monthly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
      case RecurringOptions.Yearly:
        this.workOrder.recurrence = new Recurrence({
          recurEvery: 1,
          month: 1,
          monthDay: 1,
          recurringOptionId: RecurringOptions.Yearly,
          startDate: recurStartDate,
          endDate: recurEndDate,
        });
        break;
    }
    this.setVariableOnContext();
  }

  public onWeekDayOptionsChanged(): void {
    if (
      this.workOrder.recurrence.weekDaysArray == null ||
      !this.workOrder.recurrence.weekDaysArray.length
    ) {
      this.workOrder.recurrence.weekDays = WeekDays.Sunday.toString();
      this.workOrder.recurrence.weekDaysArray = [WeekDays.Sunday];
    } else {
      this.workOrder.recurrence.weekDays =
        this.workOrder.recurrence.weekDaysArray.join(this.WEEK_DAYS_SEPARATOR);
    }
    this.setVariableOnContext();
  }
  onChangeEndDate(event: Date): void {
    this.workOrder.recurrence.endDate =
      this.utilityService.getIsoStringInLocalTime(event);
    this.setVariableOnContext();
  }

  setVariableOnContext(): void {
    this.dynamicFormContextService.setVariableData(
      'workOrder',
      'entity',
      this.workOrder
    );
  }
}
