import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiGenericService,
  ArrayUtilityService,
  DynamicDialogService,
  GenericRelation,
  InterceptorConfig,
  NavigationService,
  NotificationsService,
  ObjectsUtilityService,
  PageContextService,
  PrgDocumentsListComponent,
  PrgGenericRelationTableComponent,
  UtilityService,
} from '@prg/prg-core-lib';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToggleButton } from 'primeng/togglebutton';
import { firstValueFrom, Subscription } from 'rxjs';
import { DepartmentService } from '../../../../../Core/services/department-service/department.service';
import { TicketFormComponent } from '../../../../../tickets/components/ticket-list/components/ticket-form/ticket-form.component';
import { Ticket } from '../../../../../tickets/models/ticket.model';
import {
  WorkOrder,
  WorkOrderStates,
  WorkOrderTimer,
  WorkOrderTimerTypes,
} from '../../../../../work-orders/models/work-order-model';
import { WorkOrderChecklistService } from '../../../work-order-checklist/services/work-order-checklist.service';
import { WorkOrderEntitiesOperationNames } from '../../model/work-order-entities-operation-names-enum';
import { WorkOrderService } from '../../services/work-order.service';
import { WorkOrderSignatureComponent } from '../work-order-signature/work-order-signature.component';
import { WorkOrderTimersComponent } from '../work-order-timers/work-order-timers.component';

@Component({
  selector: 'app-work-order-panel',
  templateUrl: './work-order-panel.page.html',
  styleUrls: ['./work-order-panel.page.scss'],
})
/**
 * Page Work Order Panel
 */
export class WorkOrderPanelPage implements OnInit, OnDestroy {
  /**
   * The work order id
   * @type {string}
   */
  public workOrderId: string;

  /**
   * Currently work order
   * @type {WorkOrder}
   */
  public workOrder: WorkOrder = null;

  /**
   * Show/hide timers
   * @type {boolean}
   */
  public showTimer: boolean = false;

  /**
   * Number of operations uncompleted for this work order
   * @type {number}
   */
  public numberOfOperationsUncompleted: number;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];

  /**
   * Variable to equal to setInterval methods to show ongoing travel timers live
   * @type {number}
   */
  private intervalTravelTimeUpdate: number;
  /**\
   * Variable to equal to setInterval methods to show on going work timers live
   * @type {number}
   */
  private intervalWorkTimeUpdate: number;

  /**
   * Work order id sent by calendar component
   * @type {string}
   */
  @Input() workOrderIdByCalendar?: string = null;

  @Output() clickCreateWorkOrderFromAnother: EventEmitter<WorkOrder> =
    new EventEmitter<WorkOrder>();

  @Output() deleteWorkOrder: EventEmitter<WorkOrder> =
    new EventEmitter<WorkOrder>();

  public pageTranslationPath: string =
    'pages-techparts-solar-mobile.work-order-panel.';

  /**
   * Base translation path for the work order states in assets
   * @type {string}
   */
  public workOrderStatesTranslationPath: string =
    'lookup-tables.workorderstates.items.';

  public workOrderStates = WorkOrderStates;

  public objectsURLReports: string[] = [];

  /**
   * Property of type DynamicDialogRef to control the signature dialog
   */
  private dynamicDialogRef!: DynamicDialogRef;

  /**
   * Property of type DynamicDialogRef to control the timers dialog
   */
  private dynamicDialogTimersRef!: DynamicDialogRef;

  public parentUrl: string = '/work-orders';

  public isDepartmentManager: boolean = false;

  public menuButtonRelations: MenuItem[] = [
    {
      icon: 'pi pi-check-square',
      label: this.translateService.instant(
        'buttons.createticketfromworkorder.label'
      ),
      tooltip: this.translateService.instant(
        'buttons.createticketfromworkorder.tooltip'
      ),
      tooltipOptions: {
        tooltipLabel: this.translateService.instant(
          'buttons.createticketfromworkorder.tooltip'
        ),
      },
      command: () => this.createTicketFromWorkOrder(),
    },
    {
      icon: 'pi pi pi-calendar',
      label: this.translateService.instant(
        'buttons.createworkorderfromanother.label'
      ),
      tooltipOptions: {
        tooltipLabel: this.translateService.instant(
          'buttons.createworkorderfromanother.tooltip'
        ),
      },
      command: () => this.createWorkOrderFromAnother(),
    },
  ];
  /**
   * Constructor
   * @param utilityService
   * @param {ActivatedRoute} activatedRoute
   * @param navigationService
   * @param {WorkOrderService} workOrderService
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {NotificationsService} notificationsService
   * @param {TranslateService} translateService
   * @param {WorkOrderChecklistService} checklistService
   * @param pageContextService
   * @param router
   * @param arrayUtilityService
   * @param apiGenericService
   * @param dialogService
   */
  constructor(
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private workOrderService: WorkOrderService,
    private objectsUtilityService: ObjectsUtilityService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private checklistService: WorkOrderChecklistService,
    private pageContextService: PageContextService,
    private router: Router,
    private arrayUtilityService: ArrayUtilityService,
    private apiGenericService: ApiGenericService,
    private dialogService: DynamicDialogService,
    private departmentService: DepartmentService
  ) {
    this.isDepartmentManager =
      this.departmentService.currentUserIsDepartmentManager();
  }

  /**
   * ngOnInit
   * @returns {Promise<void>}
   */
  public ngOnInit(): void {
    if (this.workOrderIdByCalendar != null) {
      this.workOrderId = this.workOrderIdByCalendar;
    } else {
      this.workOrderId =
        this.activatedRoute.snapshot.paramMap.get('workOrderId');
    }

    if (this.workOrderId != null) {
      this.getWorkOrderData();
    }
  }

  public workOrderChecklistsData = async (checklists) => {
    if (checklists != null && checklists?.length > 0) {
      this.numberOfOperationsUncompleted = checklists.reduce((acc, obj) => {
        return acc + (obj.numberOfTasks - obj.completedTasks);
      }, 0);
    } else {
      this.numberOfOperationsUncompleted = 0;
    }
  };

  /**
   * Method to get work Order Info
   * @returns {Promise<void>}
   */
  private async getWorkOrderData() {
    try {
      const responseWorkOrder =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.ByIdWithTimers,
          new WorkOrder({
            id: this.workOrderId,
          })
        );

      if (responseWorkOrder?.entity != null) {
        this.workOrder =
          this.objectsUtilityService.clone(responseWorkOrder)?.entity;
        await this.mapTimersIntoWorkOrder();
        this.subscription.push(
          this.pageContextService.subscribeVariable(
            'workOrderChecklists',
            this.workOrderId,
            this.workOrderChecklistsData
          )
        );

        this.pageContextService.setVariableData(
          'workOrder',
          this.workOrderId,
          this.workOrder
        );
        if (
          this.router.url == '/work-orders' ||
          this.router.url == '/resources' ||
          this.router.url == `/entity-types/workorder/${this.workOrderId}`
        ) {
          if (this.router.url == '/work-orders') {
            this.parentUrl = '/work-orders';
          } else if (this.router.url == '/resources') {
            this.parentUrl = '/resources';
          } else if (
            this.router.url == `/entity-types/workorder/${this.workOrderId}`
          ) {
            this.parentUrl = this.router.url;
          }

          await this.router.navigate(
            [this.parentUrl, this.workOrderId, 'park'],
            {
              state: {
                parkId: this.workOrder.resourceId,
              },
              skipLocationChange: true,
            }
          );
        }
      }
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Map Existing timers in their work order
   */
  private async mapTimersIntoWorkOrder(): Promise<void> {
    const workOrderTimersOrganize =
      await this.workOrderService.getAndOrganizeWorkOrderTimersAsync(
        this.workOrder
      );
    this.workOrder['travelTimer'] = await workOrderTimersOrganize[0];
    if (await this.workOrder['travelTimer'].onGoingTimer) {
      const onGoingTimer: WorkOrderTimer = this.workOrder[
        'travelTimer'
      ].timers.find(
        (timer: WorkOrderTimer) =>
          timer.finalTime == null && timer.initialTime != null
      );
      this.intervalTravelTimeUpdate = setInterval(() => {
        onGoingTimer.finalTime = this.utilityService
          .getUTCDateAsLocal()
          .toISOString();
        this.workOrder['travelTimer'].totalTimer =
          this.workOrderService.sumWorkOrderTimers(
            this.workOrder['travelTimer'].timers
          );
      }, 1000);
    }
    this.workOrder['workTimer'] = await workOrderTimersOrganize[1];
    if (await this.workOrder['workTimer'].onGoingTimer) {
      const onGoingTimer: WorkOrderTimer = this.workOrder[
        'workTimer'
      ].timers.find(
        (timer: WorkOrderTimer) =>
          timer.finalTime == null && timer.initialTime != null
      );
      this.intervalWorkTimeUpdate = setInterval(() => {
        onGoingTimer.finalTime = this.utilityService
          .getUTCDateAsLocal()
          .toISOString();
        this.workOrder['workTimer'].totalTimer =
          this.workOrderService.sumWorkOrderTimers(
            this.workOrder['workTimer'].timers
          );
      }, 1000);
    }
    this.showTimer = true;
  }

  /**
   * Handle travel timer clicks
   * @param event
   * @returns {Promise<void>}
   */
  public async handleTravelTimer(event: {
    originalEvent: PointerEvent;
    checked: boolean;
  }): Promise<void> {
    await this.saveTimer(event.checked, WorkOrderTimerTypes.Travel);
  }

  /**
   * Handle work timer clicks
   * @param event
   * @returns {Promise<void>}
   */
  public async handleWorkTimer(event: {
    originalEvent: PointerEvent;
    checked: boolean;
  }): Promise<void> {
    await this.saveTimer(event.checked, WorkOrderTimerTypes.Work);
  }

  /**
   * Save Work Timer
   * @param {boolean} checkedTimer
   * @param {WorkOrderTimerTypes} timerType
   * @returns {Promise<void>}
   */
  private async saveTimer(
    checkedTimer: boolean,
    timerType: WorkOrderTimerTypes
  ) {
    try {
      const keyTimer =
        timerType == WorkOrderTimerTypes.Travel ? 'travelTimer' : 'workTimer';

      if (!checkedTimer && this.workOrder[keyTimer]?.onGoingTimer) {
        const updateTimer = this.workOrder?.workOrderTimers.find(
          (timer: WorkOrderTimer) =>
            timer.finalTime == null && timer.timerTypeId === timerType
        );

        if (updateTimer != null) {
          await this.updateTimerAsync(updateTimer);
        }
      } else if (
        checkedTimer &&
        this.workOrder[keyTimer]?.onGoingTimer == false
      ) {
        await this.saveNewTimerAsync(timerType);
      }
    } catch (e) {
      return;
    }
  }

  public async saveNewTimerAsync(timerType: WorkOrderTimerTypes) {
    const newTimerResponse =
      await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
        'workordertimer',
        WorkOrderEntitiesOperationNames.Create,
        new WorkOrderTimer({
          workOrderId: this.workOrder.id,
          name: 'timer:' + this.workOrder?.workOrderAutoNumber,
          timerTypeId: timerType,
          initialTime: new Date().toISOString(),
        })
      );

    if (newTimerResponse?.entity != null) {
      this.showTimer = false;
      if (newTimerResponse?.data != null) {
        this.workOrder = this.objectsUtilityService.clone(
          newTimerResponse?.data.workOrderUpdateState
        );
        if (
          this.arrayUtilityService.isNullOrEmptyArray(
            this.workOrder.workOrderTimers
          )
        ) {
          this.workOrder.workOrderTimers = [];
        }
        this.workOrder.workOrderTimers.push(newTimerResponse?.entity);
        this.pageContextService.setVariableData(
          'workOrder',
          this.workOrderId,
          this.workOrder
        );
      } else {
        this.workOrder.workOrderTimers.push(newTimerResponse?.entity);
      }
      clearInterval(this.intervalTravelTimeUpdate);
      clearInterval(this.intervalWorkTimeUpdate);
      await this.mapTimersIntoWorkOrder();

      this.showTimer = true;
    }
  }

  public async updateTimerAsync(updateTimer: WorkOrderTimer) {
    const updateTimerIndex =
      this.workOrder.workOrderTimers.indexOf(updateTimer);
    const updateTimerResponse =
      await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
        'workordertimer',
        WorkOrderEntitiesOperationNames.Update,
        new WorkOrderTimer({
          ...updateTimer,
          finalTime: new Date().toISOString(),
        })
      );

    if (updateTimerResponse?.entity != null) {
      this.showTimer = false;
      this.workOrder.workOrderTimers[updateTimerIndex] =
        this.objectsUtilityService.clone(updateTimerResponse.entity);
      clearInterval(this.intervalTravelTimeUpdate);
      clearInterval(this.intervalWorkTimeUpdate);
      await this.mapTimersIntoWorkOrder();

      this.showTimer = true;
    }
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    clearInterval(this.intervalWorkTimeUpdate);
    clearInterval(this.intervalTravelTimeUpdate);
    this.objectsURLReports.forEach((url) => {
      if (url != null) {
        URL.revokeObjectURL(url);
      }
    });
    this.pageContextService.unsubscribe(...this.subscription);
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  /**
   * Method that handles the actions to be made when the work order is declared as finished
   * @returns {Promise<void>}
   */
  public async onFinishWorkOrder(): Promise<void> {
    if (
      await this.notificationsService.prgConfirmationService(
        'pages-techparts-solar-mobile.work-order-panel.warning-finish',
        await firstValueFrom(
          this.translateService.get(
            'pages-techparts-solar-mobile.work-order.label'
          )
        )
      )
    ) {
      const workOrderResponse =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.FinishWorkOrder,
          {
            ...this.workOrder,
            finishDate: new Date().toISOString(),
          }
        );

      if (workOrderResponse?.entity != null) {
        this.workOrder = this.objectsUtilityService.clone(
          workOrderResponse?.entity
        );
        this.pageContextService.setVariableData(
          'workOrder',
          this.workOrderId,
          this.workOrder
        );
        clearInterval(this.intervalTravelTimeUpdate);
        clearInterval(this.intervalWorkTimeUpdate);
        await this.mapTimersIntoWorkOrder();
      } else {
        return;
      }
    } else {
      return;
    }
  }

  /**
   * Method that reopens a work order
   * @param event
   * @returns {Promise<void>}
   */
  public async onReopenWorkOrder(event: any): Promise<void> {
    event.stopPropagation();
    if (
      await this.notificationsService.prgConfirmationService(
        'pages-techparts-solar-mobile.work-order-filter-list.warning-restart',
        await firstValueFrom(
          this.translateService.get(
            'pages-techparts-solar-mobile.work-order.label'
          )
        )
      )
    ) {
      try {
        const responseUpdateWorkOrder =
          await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
            'workorder',
            WorkOrderEntitiesOperationNames.ReopenWorkOrder,
            {
              id: this.workOrderId,
            }
          );
        if (responseUpdateWorkOrder?.entity != null) {
          this.workOrder = this.objectsUtilityService.clone(
            responseUpdateWorkOrder?.entity
          );

          this.pageContextService.setVariableData(
            'workOrder',
            this.workOrderId,
            this.workOrder
          );
          await this.mapTimersIntoWorkOrder();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  }

  /**
   * Method that pops up a confirmation dialog to activate timer when other timer is already running
   * @param {ToggleButton} toggleButton
   * @param event
   * @param {string} timerType
   * @returns {Promise<void>}
   */
  public async dialogOtherTimer(
    toggleButton: ToggleButton,
    event: { originalEvent: PointerEvent; checked: boolean },
    timerType: string
  ): Promise<void> {
    const timerTypeWorkOrder =
      timerType == 'workTimer'
        ? WorkOrderTimerTypes.Work
        : WorkOrderTimerTypes.Travel;
    if (
      await this.notificationsService.prgConfirmationService(
        'pages-techparts-solar-mobile.work-order-filter-list.warning-initiate-timer',
        await firstValueFrom(
          this.translateService.get(
            'pages-techparts-solar-mobile.work-order.label'
          )
        )
      )
    ) {
      if (timerTypeWorkOrder == WorkOrderTimerTypes.Work) {
        await this.handleWorkTimer(event);
      } else {
        await this.handleTravelTimer(event);
      }
    } else {
      toggleButton.writeValue(false);
    }
  }
  /**
   * Method that handles the actions to be made when the work order is declared as closed
   * @returns {Promise<void>}
   */
  public async onCloseWorkOrder(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    if (
      await this.notificationsService.prgConfirmationService(
        'pages-techparts-solar-mobile.work-order-panel.warning-close',
        await firstValueFrom(
          this.translateService.get(
            'pages-techparts-solar-mobile.work-order.label'
          )
        )
      )
    ) {
      const workOrderResponse =
        await this.workOrderService.manageWorkOrderDataByEntityServiceAsync(
          'workorder',
          WorkOrderEntitiesOperationNames.CloseWorkOrder,
          {
            id: this.workOrderId,
          }
        );

      if (workOrderResponse?.entity != null) {
        this.workOrder = this.objectsUtilityService.clone(
          workOrderResponse?.entity
        );

        this.pageContextService.setVariableData(
          'workOrder',
          this.workOrderId,
          this.workOrder
        );
        await this.mapTimersIntoWorkOrder();
      } else {
        return;
      }
    } else {
      return;
    }
  }

  async openReport(): Promise<void> {
    try {
      if (
        await this.notificationsService.prgConfirmationService(
          this.pageTranslationPath + 'report.warning-generate',
          await firstValueFrom(
            this.translateService.get(
              'pages-techparts-solar-mobile.work-order.label'
            )
          )
        )
      ) {
        const responseReport = await this.apiGenericService.get(
          'WorkOrderReport',
          this.workOrderId,
          new InterceptorConfig({
            apiRequest: true,
            sendAuthToken: true,
            handleLoading: true,
            handleErrors: false,
            responseType: 'blob',
          })
        );
        if (responseReport != null) {
          if (!Capacitor.isNativePlatform()) {
            this.objectsURLReports.push(URL.createObjectURL(responseReport));

            if (
              this.objectsURLReports[this.objectsURLReports.length - 1] != null
            ) {
              window.open(
                this.objectsURLReports[this.objectsURLReports.length - 1],
                '_blank'
              );
            }
          }
        }
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This method will open dialog to sign work order
   */
  public openSignaturePopUp() {
    this.dynamicDialogRef = this.dialogService.openDialog(
      WorkOrderSignatureComponent,
      {
        header: this.translateService.instant(
          this.pageTranslationPath + 'signature.dialog.title'
        ),
        width: '100vw',
        height: '95vh',
        styleClass: 'dialog-signature',
        style: { 'max-width': '420px', 'max-height': '630px' },
        autoZIndex: false,
        modal: true,
        draggable: false,
        data: {
          workOrder: this.workOrder,
        },
      }
    );

    this.subscription.push(
      this.dynamicDialogRef.onClose.subscribe((item) => {
        if (item != null) {
          this.workOrder.imageUrl = item.imageUrl;
          this.workOrder.responsibleSignatureName =
            item.responsibleSignatureName;
          this.pageContextService.setVariableData(
            'workOrder',
            this.workOrderId,
            this.workOrder
          );
        }
      })
    );
  }

  openDialogTimers(): void {
    this.dynamicDialogTimersRef = this.dialogService.openDialog(
      WorkOrderTimersComponent,
      {
        header: this.translateService.instant(
          this.pageTranslationPath + 'dialog-timers.header'
        ),
        width: '90vw',

        styleClass: 'dialog-timers',
        style: { 'max-width': '650px', 'max-height': '90vh' },
        autoZIndex: false,
        modal: true,
        draggable: false,
        data: {
          workOrder: this.workOrder,
        },
      }
    );

    this.subscription.push(
      this.dynamicDialogTimersRef.onClose.subscribe(async (items) => {
        if (items != null) {
          this.showTimer = false;
          this.workOrder.workOrderTimers =
            this.arrayUtilityService.clone(items);
          await this.mapTimersIntoWorkOrder();
          this.showTimer = true;

          this.pageContextService.setVariableData(
            'workOrder',
            this.workOrderId,
            this.workOrder
          );
        }
      })
    );
  }

  createWorkOrderFromAnother(): void {
    this.clickCreateWorkOrderFromAnother.emit(this.workOrder);
  }

  createTicketFromWorkOrder(): void {
    const popupTitle: string = this.translateService.instant(
      'pages.tickets.components.ticket-form.new-ticket.label'
    );

    const newTicket: Ticket = new Ticket({
      genericRelation: new GenericRelation({
        sourceId: this.workOrder.id,
        sourceEntityType: 'WorkOrder',
        targetEntityType: 'Ticket',
      }),
      rootResourceId:
        this.workOrder.resourceId != null ? this.workOrder.resourceId : null,
      name: this.workOrder.name,
      isPublic: false,
      description: this.workOrder.description,
    });

    this.dialogService.openDialog(TicketFormComponent, {
      header: popupTitle,
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-ticket-dialog-form',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1100px' },
      data: {
        entity: newTicket,
        showNotes: true,
      },
    });
  }

  openDocumentsDialog(): void {
    this.dialogService.openDialog(PrgDocumentsListComponent, {
      header: this.translateService.instant('entities.basedocument.label'),
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-documents-dialog',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1400px' },
      data: {
        entityTypeName: 'workOrderDocument',
        referenceId: this.workOrder.id,
        documentsInTree: true,
      },
    });
  }

  openDialogRelations(): void {
    this.dialogService.openDialog(PrgGenericRelationTableComponent, {
      header: this.translateService.instant('buttons.relations.label'),
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-relations-dialog',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1000px' },
      data: {
        entity: this.workOrder,
      },
    });
  }

  async onClickDeleteButton(): Promise<void> {
    try {
      if (
        await this.notificationsService.prgConfirmationService(
          'messages.delete-confirmation',
          this.translateService.instant('entities.workorder.label')
        )
      ) {
        await this.apiGenericService.delete(
          'workorder/delete',
          this.workOrderId,
          new InterceptorConfig({
            apiRequest: true,
          })
        );

        this.deleteWorkOrder.emit(this.workOrder);
      }
    } catch (e) {
      console.log(e);
    }
  }
}
