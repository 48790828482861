import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ActionName,
  ActionOutput,
  BaseAction,
  BaseActionKey,
  BaseField,
  ButtonConfiguration,
  ControlTypes,
  EvalUtilityService,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  FormGroupService,
  ObjectsUtilityService,
  Order,
  UtilityService,
} from '@prg/prg-core-lib';

@Component({
  selector: 'tickets-filters',
  templateUrl: './tickets-filters.component.html',
  styleUrls: ['./tickets-filters.component.scss'],
})
export class TicketsFiltersComponent implements OnInit {
  @Input() filters: BaseField[];
  @Input() orderFilters: BaseField[];

  private _defaultFilters: any = {};
  private cloneDefaultFilters: any = {};
  get defaultFilters(): boolean {
    return this._defaultFilters;
  }
  @Input() set defaultFilters(defaultFilters: any) {
    if (defaultFilters != null) {
      this._defaultFilters = defaultFilters;
      this.cloneDefaultFilters =
        this.objectsUtilityService.clone(defaultFilters);
    }
  }
  @Input() filterOnValueChange: boolean = false;

  @Output() filterGroupCreated = new EventEmitter<FilterGroup>();
  //TODO create base action key apply and clear
  public filtersActions: BaseAction[] = [
    new ButtonConfiguration({
      controlName: ActionName.Button,
      key: BaseActionKey.Apply,
      disableWhenFormInvalid: false,
      icon: 'pi pi-check',
    }),
    new ButtonConfiguration({
      controlName: ActionName.Button,
      key: BaseActionKey.ClearAll,
      disableWhenFormInvalid: false,
      icon: 'pi pi-filter-slash',
    }),
  ];
  public form: FormGroup = null;
  public fieldTypes = ControlTypes;

  constructor(
    private formGroupService: FormGroupService,
    private utilityService: UtilityService,
    private objectsUtilityService: ObjectsUtilityService,
    private evalUtilityService: EvalUtilityService
  ) {}

  ngOnInit() {
    this.form = this.formGroupService.toFormGroupOneObject(
      this.defaultFilters,
      [...this.filters, ...this.orderFilters]
    );

    this.cloneDefaultFilters = this.objectsUtilityService.clone(
      this.form.value
    );

    this.createFilterGroup();

    if (this.filterOnValueChange) {
      this.filtersActions = [
        new ButtonConfiguration({
          controlName: ActionName.Button,
          key: BaseActionKey.ClearAll,
          disableWhenFormInvalid: false,
          icon: 'pi pi-filter-slash',
        }),
      ];
    }
  }

  /**
   * return form group based on baseParh
   * @param basepath
   * @returns
   */
  public getFormGroup(basepath: string) {
    return basepath != null
      ? this.formGroupService.getBasePathFieldFormGroup(
          basepath.split('.'),
          this.form
        )
      : this.form;
  }

  public onFieldChanged(event: any) {
    if (this.filterOnValueChange) {
      this.createFilterGroup();
    }
  }

  /**
   * this function fire the output
   * @param actionOutput
   */
  public async onActionClicked(actionOutput: ActionOutput): Promise<void> {
    switch (actionOutput.action) {
      case BaseActionKey.Apply:
        this.createFilterGroup();
        break;
      case BaseActionKey.ClearAll:
        this.form.setValue(this.cloneDefaultFilters);
        this.createFilterGroup();
        break;
    }
  }
  /**
   * this function calls the service that does the expression eval for fields
   * @param {string} expression
   * @param {boolean} defaultValue
   * @param {string | null} formFieldGroup
   * @returns {boolean}
   */
  public evalExpression(
    expression: string,
    defaultValue: boolean = false
  ): boolean {
    if (!expression) return defaultValue;

    return this.evalUtilityService.evalFunction(expression, this);
  }

  private createFilterGroup(): void {
    const filterGroup: FilterGroup = new FilterGroup();

    const filterFieldsKeys = this.filters.map((f) => f.key);
    const orderFieldsKeys = this.orderFilters.map((of) => of.key);

    filterFieldsKeys.forEach((fk) => {
      if (this.form.value[fk] != null) {
        if (filterGroup.filterCollections != null) {
          filterGroup.filterCollections.push(
            new Filter({
              startGroup: true,
              propertyName: this.utilityService.capitalizeFirstLetter(fk),
              filterOperation: FilterOperations.EqualTo,
              value: this.form.value[fk],
              filterExpression: FilterExpressions.And,
            })
          );
        } else {
          filterGroup.filterCollections = [
            new Filter({
              startGroup: true,
              propertyName: this.utilityService.capitalizeFirstLetter(fk),
              filterOperation: FilterOperations.EqualTo,
              value: this.form.value[fk],
              filterExpression: FilterExpressions.And,
            }),
          ];
        }
      }
    });

    orderFieldsKeys.forEach((ofk) => {
      const formPropertyValue = this.form.value[ofk];
      if (formPropertyValue != null) {
        if (filterGroup.orderCollection != null) {
          filterGroup.orderCollection.push(
            new Order({
              propertyName: formPropertyValue['property'],
              orderType: formPropertyValue['orderType'],
            })
          );
        } else {
          filterGroup.orderCollection = [
            new Order({
              propertyName: formPropertyValue['property'],
              orderType: formPropertyValue['orderType'],
            }),
          ];
        }
      }
    });
    this.filterGroupCreated.emit(filterGroup);
  }
}
