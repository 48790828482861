import { Injectable } from '@angular/core';

import {
  ApiGenericService,
  InterceptorConfig,
  IsLoadingDataService,
  User,
  UtilityService,
} from '@prg/prg-core-lib';
import {
  AddUserAvailabilitiesRequest,
  UpdatedUserAvailabilitiesResponse,
  UserAvailability,
} from '../models/user-availability.model';

@Injectable({
  providedIn: 'root',
})
export class UserAvailabilitiesService {
  constructor(
    private utilityService: UtilityService,
    private apiGenericService: ApiGenericService,
    private isLoadingDataService: IsLoadingDataService
  ) {}

  public getAvailableUsersByDateIntervalAsync(
    startDate: Date,
    endDate: Date,
    workOrderId: string = null,
    departmentId: string = null
  ): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      this.apiGenericService
        .get(
          'useravailability/getavailableusersbydateinterval/' +
            this.utilityService.getIsoStringInLocalTime(startDate) +
            '/' +
            this.utilityService.getIsoStringInLocalTime(endDate) +
            (workOrderId != null ? '/' + workOrderId : '') +
            (departmentId != null ? `?departmentId=${departmentId}` : ''),
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            resolve(response.entity);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public getUserAvailabilitiesByDateIntervalAsync(
    startDate: Date,
    endDate: Date,
    userIds: string[] = [],
    onCall: boolean = null
  ): Promise<UserAvailability[]> {
    endDate = this.setDateTimeToEndOfDay(new Date(endDate));
    return new Promise<UserAvailability[]>((resolve, reject) => {
      this.isLoadingDataService.loadingWithoutSpinner();
      let url =
        'useravailability/getavailabilitiesbydateintervalminimal/' +
        this.utilityService.getIsoStringInLocalTime(startDate) +
        '/' +
        this.utilityService.getIsoStringInLocalTime(endDate);
      if (onCall != null) {
        url += '/' + onCall;
      }
      this.apiGenericService
        .post(
          url,
          userIds,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: false,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            response.entity.forEach((availability: UserAvailability) => {
              availability.startDate = new Date(availability.startDate);
              availability.endDate = new Date(availability.endDate);
            });
            this.isLoadingDataService.hide();
            resolve(response.entity);
          },
          (reason) => {
            this.isLoadingDataService.hide();
            reject(reason);
          }
        );
    });
  }

  public addUserAvailabilitiesAsync(
    startDate: Date,
    endDate: Date,
    onCall: boolean,
    force: boolean,
    userIds: string[],
    userAvailability: UserAvailability
  ): Promise<UpdatedUserAvailabilitiesResponse> {
    endDate = this.setDateTimeToEndOfDay(new Date(endDate));
    userAvailability.endDate = this.setDateTimeToEndOfDay(
      new Date(userAvailability.endDate)
    );
    userAvailability.startDate = this.utilityService.getIsoStringInLocalTime(
      userAvailability.startDate
    );
    userAvailability.endDate = this.utilityService.getIsoStringInLocalTime(
      userAvailability.endDate
    );

    return new Promise<UpdatedUserAvailabilitiesResponse>((resolve, reject) => {
      this.apiGenericService
        .post(
          'useravailability/addavailabilitiesbydateinterval/' +
            this.utilityService.getIsoStringInLocalTime(startDate) +
            '/' +
            this.utilityService.getIsoStringInLocalTime(endDate) +
            '/' +
            onCall +
            '/' +
            force,
          new AddUserAvailabilitiesRequest({
            userIds: userIds,
            userAvailability: userAvailability,
          }),
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: false,
          })
        )
        .then(
          (response) => {
            response.entity.forEach((availability: UserAvailability) => {
              availability.startDate = new Date(availability.startDate);
              availability.endDate = new Date(availability.endDate);
            });

            if (response != null && response.entity != null) {
              response.entity.forEach((availability: UserAvailability) => {
                availability.startDate = new Date(availability.startDate);
                availability.endDate = new Date(availability.endDate);
              });
              resolve(
                new UpdatedUserAvailabilitiesResponse(
                  response.entity,
                  response.data['RemovedAvailabilities'] ?? []
                )
              );
            } else {
              resolve(new UpdatedUserAvailabilitiesResponse([], []));
            }
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public updateUserAvailabilityAsync(
    id: string,
    startDate: Date,
    endDate: Date,
    onCall: boolean,
    force: boolean
  ): Promise<UpdatedUserAvailabilitiesResponse> {
    endDate = this.setDateTimeToEndOfDay(new Date(endDate));
    return new Promise<UpdatedUserAvailabilitiesResponse>((resolve, reject) => {
      this.apiGenericService
        .put(
          'useravailability/updateavailabilitybydateinterval/' +
            id +
            '/' +
            this.utilityService.getIsoStringInLocalTime(startDate) +
            '/' +
            this.utilityService.getIsoStringInLocalTime(endDate) +
            '/' +
            onCall +
            '/' +
            force,
          null,
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: false,
          })
        )
        .then(
          (response) => {
            if (response != null && response.entity != null) {
              response.entity.forEach((availability: UserAvailability) => {
                availability.startDate = new Date(availability.startDate);
                availability.endDate = new Date(availability.endDate);
              });
              resolve(
                new UpdatedUserAvailabilitiesResponse(
                  response.entity,
                  response.data['RemovedAvailabilities'] ?? []
                )
              );
            }
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public removeUserAvailabilitiesAsync(
    startDate: Date,
    endDate: Date,
    force: boolean,
    userIds: string[]
  ): Promise<UpdatedUserAvailabilitiesResponse> {
    endDate = this.setDateTimeToEndOfDay(new Date(endDate));
    return new Promise<UpdatedUserAvailabilitiesResponse>((resolve, reject) => {
      this.apiGenericService
        .post(
          'useravailability/removeavailabilitiesbydateinterval/' +
            this.utilityService.getIsoStringInLocalTime(startDate) +
            '/' +
            this.utilityService.getIsoStringInLocalTime(endDate) +
            '/' +
            force,
          userIds,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            if (
              response != null &&
              response.data['NewAvailabilities'] != null
            ) {
              response.data['NewAvailabilities'].forEach(
                (availability: UserAvailability) => {
                  availability.startDate = new Date(availability.startDate);
                  availability.endDate = new Date(availability.endDate);
                }
              );
              resolve(
                new UpdatedUserAvailabilitiesResponse(
                  response.data['NewAvailabilities'] ?? [],
                  response.entity
                )
              );
            } else {
              resolve(new UpdatedUserAvailabilitiesResponse([], []));
            }
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }
  private setDateTimeToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 0));
  }
}
