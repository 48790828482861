import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractEntityTypeService,
  ApiGenericService,
  ArrayUtilityService,
  BaseCacheService,
  InterceptorConfig,
  NotificationsService,
  ObjectsUtilityService,
  ResultObject,
  UtilityService,
} from '@prg/prg-core-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  TimerUnits,
  WorkOrder,
  WorkOrderTimer,
  WorkOrderTimerControl,
  WorkOrderTimerTypes,
} from '../../../../work-orders/models/work-order-model';
import { WorkOrderTotalTime } from '../components/work-order-timers/work-order-timers.component';
import { WorkOrderEntitiesOperationNames } from '../model/work-order-entities-operation-names-enum';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})

/**
 * REAL WorkOrderService implementation
 */
export class WorkOrderService extends BaseCacheService<WorkOrder> {
  private readonly WORK_Order_ENDPOINT: string = 'workorder';
  private readonly WORK_Order_Timer_ENDPOINT: string = 'workordertimer';
  /**
   * Constructor
   * @param {AbstractEntityTypeService} entityTypeService
   * @param {ObjectsUtilityService} objectUtility
   * @param {UtilityService} utilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param apiGenericService
   * @param {TranslateService} translateService
   * @param notificationsService
   */
  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private objectUtility: ObjectsUtilityService,
    private utilityService: UtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private apiGenericService: ApiGenericService,
    translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {
    super(translateService, '');
  }

  /**
   * Last url before enter work order panel
   */
  protected lastUrlWorkOrderListSubject =
    new BehaviorSubject<LastUrlWorkOrderList>(null);

  /**
   * Setter for lastUrlWorkOrderListSubject
   * @param {LastUrlWorkOrderList} value
   * @protected
   */
  public setLastUrlWorkOrderList(value: LastUrlWorkOrderList): void {
    this.lastUrlWorkOrderListSubject.next(value);
  }

  /**
   * Getter for lastUrlWorkOrderListSubject
   *
   * @returns The lastUrlWorkOrderListSubject as an observable
   */
  public getLastUrlWorkOrderListObservable(): Observable<LastUrlWorkOrderList> {
    return this.lastUrlWorkOrderListSubject.asObservable();
  }

  /**
   * Getter for lastUrlWorkOrderListSubject
   *
   * @returns The lastUrlWorkOrderListSubject current value
   */
  public getLastUrlWorkOrderListValue(): LastUrlWorkOrderList {
    return this.lastUrlWorkOrderListSubject.getValue();
  }

  /**
   * Manage Work Order Data through the entity service
   * @param  {string} entityName
   * @param {string} operationName
   * @param {null} entity
   * @returns {Promise<any>}
   */
  public manageWorkOrderDataByEntityServiceAsync(
    entityName: string,
    operationName: WorkOrderEntitiesOperationNames,
    entity: any = null
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const entityType =
          await this.entityTypeService.getAllEntityTypeDataByName(entityName);
        if (entityType != null) {
          const operationRequest = entityType.operations.find(
            (operation) => operation.name == operationName
          );
          if (operationRequest != null) {
            const entityRequest = await this.entityTypeService.executeAction(
              entityName,
              operationRequest,
              entity
            );
            resolve(entityRequest);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get all timers related to work order and organize them
   * @param {WorkOrder} workOrder
   * @returns {Promise<[WorkOrderTimerControl, WorkOrderTimerControl]>}
   */
  public getAndOrganizeWorkOrderTimersAsync(
    workOrder: WorkOrder
  ): Promise<[WorkOrderTimerControl, WorkOrderTimerControl]> {
    return new Promise<[WorkOrderTimerControl, WorkOrderTimerControl]>(
      (resolve, reject) => {
        try {
          let travelTimerControl: WorkOrderTimerControl = {
            onGoingTimer: false,
            totalTimer: null,
            hasTimer: false,
          };
          let workTimerControl: WorkOrderTimerControl = {
            onGoingTimer: false,
            totalTimer: null,
            hasTimer: false,
          };

          const workOrderTimers: WorkOrderTimer[] =
            this.arrayUtilityService.clone(workOrder.workOrderTimers);
          if (this.arrayUtilityService.isNotNullOrEmptyArray(workOrderTimers)) {
            const travelTimers: WorkOrderTimer[] = workOrderTimers.filter(
              (timer) => timer.timerTypeId == WorkOrderTimerTypes.Travel
            );
            const workTimers: WorkOrderTimer[] = workOrderTimers.filter(
              (timer) => timer.timerTypeId == WorkOrderTimerTypes.Work
            );
            if (travelTimers != null && travelTimers?.length > 0) {
              travelTimerControl = this.objectUtility.clone(
                this.calcTotalTimeOfTimers(travelTimers)
              );
            }
            if (workTimers != null && workTimers?.length > 0) {
              workTimerControl = this.objectUtility.clone(
                this.calcTotalTimeOfTimers(workTimers)
              );
            }
          }
          resolve([travelTimerControl, workTimerControl]);
        } catch (e) {
          reject(e);
        }
      }
    );
  }

  /**
   * Calculate the total time  (days, hours, minutes and seconds) spent on work order
   * @param {WorkOrderTimer[]} timers
   * @returns {WorkOrderTimerControl}
   */
  public calcTotalTimeOfTimers(
    timers: WorkOrderTimer[]
  ): WorkOrderTimerControl {
    const controlTime: WorkOrderTimerControl = {
      onGoingTimer: false,
      hasTimer: true,
      totalTimer: null,
      timers: [],
    };
    controlTime.hasTimer = true;
    const finishTimers = timers.filter(
      (timer) => timer.finalTime != null && timer.initialTime != null
    );

    if (finishTimers != null && finishTimers?.length > 0) {
      controlTime.totalTimer = this.sumWorkOrderTimers(finishTimers);
      controlTime.timers = this.arrayUtilityService.clone(finishTimers);
    }
    const onGoingTimer = timers.find(
      (timer) => timer.finalTime == null && timer.initialTime != null
    );
    if (onGoingTimer != null) {
      controlTime.timers.push(onGoingTimer);
      controlTime.onGoingTimer = true;
    }

    return controlTime;
  }

  public sumWorkOrderTimers(timers: WorkOrderTimer[]): TimerUnits {
    const sumTimers: TimerUnits[] = timers.map((timer) => {
      return <TimerUnits>(
        this.utilityService.differenceBetweenDateTimes(
          new Date(timer.initialTime),
          new Date(timer.finalTime)
        )
      );
    });

    const sumTimersInUnix = sumTimers.map((timer) => {
      return {
        days: +timer.days * 86400,
        hours: +timer.hours * 3600,
        minutes: +timer.minutes * 60,
        seconds: +timer.seconds,
      };
    });

    const totalTimeInUnix = sumTimersInUnix.reduce(function (
      previousValue,
      currentValue
    ) {
      return (
        previousValue +
        currentValue.days +
        currentValue.hours +
        currentValue.minutes +
        currentValue.seconds
      );
    },
    0);

    if (totalTimeInUnix != null) {
      return <TimerUnits>(
        this.utilityService.differenceBetweenDateTimes(
          new Date(0),
          new Date(totalTimeInUnix * 1000)
        )
      );
    } else {
      return null;
    }
  }

  /**
   * Create Work Order Signature
   * @param {WorkOrder} workOrder
   * @returns {Promise<any>}
   */
  public createWorkOrderSignature(workOrder: WorkOrder): Promise<ResultObject> {
    return new Promise<ResultObject>((resolve, reject) => {
      this.apiGenericService
        .put(
          this.utilityService.normalizeUrl(
            this.WORK_Order_ENDPOINT,
            WorkOrderEntitiesOperationNames.CreateSignatureWorkOrder
          ),
          workOrder,
          workOrder.id,
          new InterceptorConfig({
            apiRequest: true,
          })
        )
        .then(
          (response) => {
            if (response != null && response.entity != null) {
              this.notificationsService.successNotification({
                translateKeys: false,
                titleKey: 'Assinatura OT',
                detailKey: 'A assinatura foi gravada com sucesso',
                data: null,
                life: 0,
              });
              resolve(response);
            } else {
              resolve(null);
            }
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  /**
   * Update WorkOrder Timers
   * @returns {Promise<any>}
   * @param workOrderId
   * @param timers
   */
  public updateWorkOrderTimersAsync(
    workOrderId: string,
    timers: WorkOrderTotalTime
  ): Promise<WorkOrderTimer[]> {
    return new Promise<WorkOrderTimer[]>((resolve, reject) => {
      this.apiGenericService
        .put(
          this.utilityService.normalizeUrl(
            this.WORK_Order_Timer_ENDPOINT,
            WorkOrderEntitiesOperationNames.UpdateWorkOrderTimers
          ),
          timers,
          workOrderId,
          new InterceptorConfig({
            apiRequest: true,
          })
        )
        .then(
          (response) => {
            if (response != null && response.entity != null) {
              resolve(response.entity);
            } else {
              resolve(null);
            }
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }
}

export interface LastUrlWorkOrderList {
  workOrderId: string;
  previousUrl: string;
}
