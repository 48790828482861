import {
  FilterOperations,
  OrderTypes,
  PrgFilterMenuItem,
  PrgFilterMenuItemChild,
} from '@prg/prg-core-lib';

export class TicketGroupOrderBy {
  propertyName: string;
  label: string;
  orderType: OrderTypes;
  active: boolean;
  order: number;

  /**
   * Constructor
   * @param {Partial<TicketGroupOrderBy>} init
   */
  constructor(init?: Partial<TicketGroupOrderBy>) {
    Object.assign(this, init);
  }
}

export const KanbanBoardFilters: PrgFilterMenuItem[] = [
  new PrgFilterMenuItem({
    propertyName: 'TicketTypeId',
    expanded: false,
    label: 'entities.ticket.fields.tickettypeid.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-slack',
    items: [],
  }),
  new PrgFilterMenuItem({
    propertyName: 'TicketPriorityId',
    expanded: false,
    label: 'entities.ticket.fields.ticketpriorityid.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-exclamation-circle',
    items: [],
  }),
  new PrgFilterMenuItem({
    propertyName: 'RootResourceId',
    expanded: false,
    label: 'entities.ticket.fields.rootresourceid.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-th-large',
    items: [
      new PrgFilterMenuItemChild({
        filterOperation: FilterOperations.IsNull,
        value: null,
        label: 'pages.kanban-board.filter-options.rootresource.empty.label',
        translatedItem: true,
        active: false,
      }),
    ],
  }),
  new PrgFilterMenuItem({
    propertyName: 'ClientId',
    expanded: false,
    label: 'entities.ticket.fields.clientid.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-id-card',
    items: [
      new PrgFilterMenuItemChild({
        filterOperation: FilterOperations.IsNull,
        value: null,
        label: 'pages.kanban-board.filter-options.client.empty.label',
        translatedItem: true,
        active: false,
      }),
    ],
  }),

  new PrgFilterMenuItem({
    propertyName: 'TicketUsers[UserId]',
    expanded: false,
    label: 'entities.ticket.fields.ticketusers.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-user',
    items: [
      /*new PrgFilterMenuItemChild({
      filterOperation: FilterOperations.IsNull,
      value: null,
      label: 'pages.kanban-board.filter-options.client.empty.label',
      translatedItem: true,
      active: false,
    }),*/
    ],
  }),
  new PrgFilterMenuItem({
    propertyName: 'Tags[Id]',
    expanded: false,
    label: 'entities.ticket.fields.tags.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-tags',
    items: [],
  }),

  new PrgFilterMenuItem({
    propertyName: 'DueDate',
    expanded: false,
    label: 'entities.ticket.fields.duedate.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-calendar-times',
    items: [],
  }),
  new PrgFilterMenuItem({
    propertyName: 'OpenOn',
    expanded: false,
    label: 'entities.ticket.fields.openon.label',
    translatedItem: true,
    multipleSelection: true,
    icon: 'pi pi-calendar-times',
    items: [],
  }),
];

export const KanbanBoardTicketOrder: TicketGroupOrderBy[] = [
  new TicketGroupOrderBy({
    propertyName: 'ticketPriorityId',
    label: 'entities.ticket.fields.ticketpriorityid.label',
    orderType: OrderTypes.Ascending,
    order: 1,
    active: true,
  }),
  new TicketGroupOrderBy({
    propertyName: 'dueDate',
    label: 'entities.ticket.fields.duedate.label',
    orderType: OrderTypes.Ascending,
    order: 2,
    active: true,
  }),
  new TicketGroupOrderBy({
    propertyName: 'name',
    label: 'entities.ticket.fields.name.label',
    orderType: OrderTypes.Ascending,
    order: null,
    active: false,
  }),
  new TicketGroupOrderBy({
    propertyName: 'openOn',
    label: 'entities.ticket.fields.openon.label',
    orderType: OrderTypes.Ascending,
    order: null,
    active: false,
  }),
];
