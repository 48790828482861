import { Injectable } from '@angular/core';

import {
  ApiGenericService,
  InterceptorConfig,
  UtilityService,
} from '@prg/prg-core-lib';
import { ChecklistOperation } from '../models/work-order-model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderOperationsService {
  private static ControllerUrl = 'checklistoperation';
  constructor(
    private apiGenericService: ApiGenericService,
    private utilityService: UtilityService
  ) {}

  public deleteAsync(operation: ChecklistOperation): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiGenericService
        .delete(
          WorkOrderOperationsService.ControllerUrl,
          operation.id,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          () => {
            resolve();
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public saveAsync(operation: ChecklistOperation): Promise<ChecklistOperation> {
    return new Promise<ChecklistOperation>((resolve, reject) => {
      if (operation.id == null || operation.id.length == 0) {
        this.apiGenericService
          .post(
            WorkOrderOperationsService.ControllerUrl,
            operation,
            new InterceptorConfig({
              apiRequest: true,
              handleLoading: true,
              handleErrors: true,
            })
          )
          .then(
            (response) => {
              resolve(response.entity);
            },
            (reason) => {
              reject(reason);
            }
          );
      } else {
        this.apiGenericService
          .put(
            WorkOrderOperationsService.ControllerUrl,
            operation,
            operation.id,
            new InterceptorConfig({
              apiRequest: true,
              handleLoading: true,
              handleErrors: true,
            })
          )
          .then(
            (response) => {
              resolve(response.entity);
            },
            (reason) => {
              reject(reason);
            }
          );
      }
    });
  }

  public getOtherOperationsAsync(
    workOrderId: string
  ): Promise<ChecklistOperation[]> {
    return new Promise<ChecklistOperation[]>((resolve, reject) => {
      this.apiGenericService
        .get(
          this.utilityService.normalizeUrl(
            WorkOrderOperationsService.ControllerUrl,
            'getotheroperations'
          ),
          workOrderId,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          (response) => {
            resolve(response.entity);
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }

  public reorderOperationsAsync(
    orderedList: Map<string, number>
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const convMap = {};
      orderedList.forEach((val: number, key: string) => {
        convMap[key] = val;
      });
      this.apiGenericService
        .patch(
          this.utilityService.normalizeUrl(
            WorkOrderOperationsService.ControllerUrl,
            'reorderoperations'
          ),
          convMap,
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
          })
        )
        .then(
          () => {
            resolve();
          },
          (reason) => {
            reject(reason);
          }
        );
    });
  }
}
