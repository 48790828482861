import { Validators } from '@angular/forms';
import {
  ActionName,
  BaseAction,
  BaseActionKey,
  EditorOutputValue,
  EditorTextConfiguration,
  FieldParentClassNames,
  InputTextAreaConfiguration,
  InputTextConfiguration,
  ReferenceType,
  SelectConfiguration,
  ToolbarSlot,
} from '@prg/prg-core-lib';

/**
 * Constant that defines the fields and actions for the dynamic form config items
 */
export const OPERATIONS_DYNAMIC_FORM = {
  fields: [
    new InputTextConfiguration({
      key: 'name',
      validators: [Validators.required],
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoTwoColumns,
      orderField: 1,
    }),
    new InputTextAreaConfiguration({
      key: 'description',
      validators: [Validators.required],
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoAColumn,
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      orderField: 6,
    }),

    new SelectConfiguration({
      key: 'operationParametersTypesId', // TODO: será uma lookup table
      optionLabel: 'name',
      optionValue: 'id',
      validators: [Validators.required],
      options: [
        { id: 'parametertypes.decimal', name: 'DecimalValue' },
        { id: 'parametertypes.okri', name: 'Ok | R | I' },
        { id: 'parametertypes.cncna', name: 'C | NC | NA' },
      ],
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      showClear: true,
      appendTo: 'body',
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoTwoColumns,
      orderField: 2,
    }),
    new SelectConfiguration({
      referenceType: ReferenceType.LookupTable,
      referenceName: 'resourcetypes',
      key: 'operationParametersResourceTypeId',
      optionLabel: 'label',
      optionValue: 'id',
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      showClear: true,
      appendTo: 'body',
      visibleExpression: 'false',
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoTwoColumns,
      orderField: 3,
    }),
    new SelectConfiguration({
      referenceType: ReferenceType.LookupTable,
      referenceName: 'resourcetypes',
      key: 'resourceTypeId',
      optionLabel: 'label',
      optionValue: 'id',
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      showClear: true,
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoTwoColumns,
      appendTo: 'body',
      visibleExpression:
        'this.form.value!=null && this.form.value.resourceId===null',
      orderField: 4,
    }),
    // new TreeSelectResourceConfiguration({
    //   key: 'resourceId',
    //   basePathTranslation: 'entities.workorderchecklistoperation.fields',
    //   showClear: true,
    //   appendTo: 'body',
    //   fieldParentClass: FieldParentClassNames.SplitFieldsIntoTwoColumns,
    //   visibleExpression:
    //     'this.form.value!=null && this.form.value.resourceTypeId===null',
    //   scrollHeight: '250px',
    //   orderField: 5,
    // }),
    new EditorTextConfiguration({
      key: 'helpText',
      disabledOutputEvent: false,
      basePathTranslation: 'entities.workorderchecklistoperation.fields',
      showEditorHeader: true,
      allowImages: false,
      fieldParentClass: FieldParentClassNames.SplitFieldsIntoAColumn,
      orderField: 7,
      outputType: EditorOutputValue.HtmlValue,
    }),
  ],
  actions: [
    // new BaseAction({
    //   controlName: ActionName.Button,
    //   key: BaseActionKey.Add,
    //   disableWhenFormInvalid: false,
    //   toolbarSlot: ToolbarSlot.Right,
    //   visibleExpression: "this.viewMode==='read'",
    // }),
    // new BaseAction({
    //   controlName: ActionName.Button,
    //   key: BaseActionKey.Edit,
    //   disableWhenFormInvalid: false,
    //   toolbarSlot: ToolbarSlot.Right,
    //   visibleExpression:
    //     "this.viewMode==='read' && this.entity.id != null && this.entity.id.length > 0",
    // }),
    // new BaseAction({
    //   controlName: ActionName.Button,
    //   key: BaseActionKey.Save,
    //   disableWhenFormInvalid: true,
    //   toolbarSlot: ToolbarSlot.Left,
    //   visibleExpression: "this.viewMode==='edit' && this.entity != null",
    // }),
    // new BaseAction({
    //   controlName: ActionName.Button,
    //   key: BaseActionKey.Cancel,
    //   disableWhenFormInvalid: false,
    //   toolbarSlot: ToolbarSlot.Right,
    //   visibleExpression: "this.viewMode==='edit' && this.entity != null",
    // }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Delete,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      confirmationModal: 'messages.delete-confirmation',
      scope: 'checklistoperation:delete',
      visibleExpression:
        "this.form!=null && this.form.value != null && this.form.value.id != null && this.form.value.id.length > 0 && this.viewMode!=='add'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Add,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='add' || this.viewMode ==='read'",
      scope: 'checklistoperation:create',
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Edit,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='read'",
      scope: 'checklistoperation:update',
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Save,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression:
        "this.form!=null && this.form.value != null && this.form.value.id == null && this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Cancel,
      disableWhenFormInvalid: false,
      toolbarSlot: ToolbarSlot.Left,
      visibleExpression: "this.viewMode==='edit'",
    }),
    new BaseAction({
      controlName: ActionName.Button,
      key: BaseActionKey.Update,
      disableWhenFormInvalid: true,
      toolbarSlot: ToolbarSlot.Left,
      scope: 'checklistoperation:update',
      visibleExpression:
        "this.form!=null && this.form.value != null && this.form.value.id != null && this.form.value.id.length > 0 && this.viewMode==='edit'",
    }),
  ],
};

export const creationLogic = new Map<string, any>([
  [
    'ParameterTypesId.CNCNA',
    {
      parameters: [
        {
          universalState: '1',
          visibilityExpression: null,
          guiSettings: null,
          closeValues: ['C', 'NA'],
          parameterFieldTypeId: 'parameterfieldtypes.selectbutton',
          resourceId: '1.15',
          options: ['C', 'NC', 'NA'],
          order: 0,
          name: 'condition',
          id: 'ff5792c4-849d-47ba-b2f5-c1ba21ebc7c6',
          createdBy: '1',
          workOrderChecklistOperationId: '3cb095c1-ca3c-4a49-a905-9f7ef8442891',
          value: null,
        },
        {
          universalState: '1',
          visibilityExpression:
            "this.parametersValue.get([0]).value.value == 'NC'",
          guiSettings: null,
          closeValues: ['R'],
          parameterFieldTypeId: 'parameterfieldtypes.selectbutton',
          resourceId: '1.15',
          options: ['R', 'I'],
          order: 1,
          name: 'action',
          id: '8eade235-7f9d-42d4-9d77-ff80d6f09830',
          createdBy: '1',
          workOrderChecklistOperationId: '3cb095c1-ca3c-4a49-a905-9f7ef8442891',
          value: null,
        },
        {
          universalState: '1',
          visibilityExpression:
            "this.parametersValue.get([0]).value.value == 'NC' && this.parametersValue.get([1]).value.value == 'I'",
          guiSettings: null,
          closeValues: ['1', '2', '3'],
          parameterFieldTypeId: 'parameterfieldtypes.selectbutton',
          resourceId: '1.15',
          options: ['1', '2', '3'],
          order: 2,
          name: 'priority',
          id: '73e74c6e-3f53-4e75-9b78-6a9f071b6dfa',
          createdBy: '1',
          workOrderChecklistOperationId: '3cb095c1-ca3c-4a49-a905-9f7ef8442891',
          value: null,
        },
      ],
    },
  ],
]);
